@import "../../../styles/consts.scss";

.FullPagePanel {
  flex-wrap: nowrap;
  position: absolute;
  left: -40px;
  top: -40px;
  right: -40px;
  bottom: -40px;
  min-width: 100%;
  min-height: 100%;
  background: $color-gray-fa;
  z-index: 9;
  &__header {
    background: white;
    padding: 24px 40px;
    box-shadow: 4px 2px 10px 0 rgba(124, 124, 124, 0.04), 4px 4px 7px 0 rgba(0, 0, 0, 0.03);
    &__title {
      color: $color-gray-6;
      font-weight: 600;
      font-size: 2rem;
      font-family: "Poppins";
      img {
        margin-right: 12px;
      }
    }
  }
  &__body {
    padding: 4px 24px;
    flex: 1;
    width: 100%;
    max-width: 1120px;
    align-self: center;
  }
  &__section {
    padding: 20px 16px;
    border-bottom: $box-border-dotted;
    &__title {
      font-family: "Poppins";
      font-size: 1.3rem;
      font-weight: 500;
      color: $color-gray-6;
      margin-bottom: 8px;
    }
    &__row {
      margin-bottom: 12px;
      display: flex;
      flex: 1 1 0px;
      justify-content: space-between;
      &__column {
        display: flex;
        flex-basis: 100%;
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }
        & > div {
          flex-basis: 100%;
          margin-right: 12px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      @media (max-width: 1024px) {
        flex-wrap: wrap;
        &__column {
          margin: 0;
          &:first-child {
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .MuiFormControl-root .MuiOutlinedInput-input {
    padding: 0 14px;
    height: 32px;
    line-height: 32px;
  }
  .MuiSelect-iconOutlined {
    right: 0;
    top: 9px;
  }
  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 11px) scale(1);
  }
}

.DocDetail {
  &__body {
    margin: 80px 24px 0 !important;
    padding: 0 40px !important;
  }
  &__main-info {
    width: 100%;
    margin-top: 16px;
    padding: 16px 24px;
    background: white;
    margin-bottom: 27px;
    border: $box-border;
    border-radius: 3px;
    &__col {
      flex: 1;
    }
    &__item {
      margin-bottom: 8px;
      font-size: 1.3rem;
      line-height: 1.54;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__attachments {
    font-size: 1.3rem;
    margin-bottom: 16px;
    &__header {
      width: 100%;
      margin-bottom: 16px;
    }
    &__title {
      font-family: "Poppins";
      font-size: 1.6rem;
      font-weight: 600;
      color: $color-gray-6;
    }
    .DocAttachment {
      width: 362px;
      height: 293px;
      padding: 24px;
      background: white;
      border-radius: 3px;
      border: $box-border;
      margin-right: 16px;
      margin-bottom: 16px;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
      &__image {
        margin-top: 16px;
        margin-bottom: 20px;
        width: 100%;
        height: 176px;
        overflow: hidden;
        border: solid 2px $color-gray-eb;
        img {
          width: auto;
          height: auto;
          min-width: 100%;
          min-height: 100%;
          max-width: 100%;
        }
        &--pdf {
          background: $color-gray-fa;
          img {
            width: 51px;
            min-width: initial;
            min-height: initial;
            max-width: initial;
          }
        }
      }
      &__code {
        color: $color-gray-6;
        font-size: 1.4rem;
        font-family: "Poppins";
        font-weight: 600;
      }
      &__type {
        color: $color-gray-9;
        font-size: 1.3rem;
      }
      &__switch {
        color: $color-gray-6;
        font-size: 1.1rem;
        font-family: "Poppins";
        font-weight: 500;
        .SwitchInput {
          margin-left: 12px;
        }
      }
    }
  }

  &__preview {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: $preview-background;
    &__container {
      background: white;
      padding: 24px;
      position: relative;
    }
    &__close {
      position: absolute;
      right: 6px;
      top: 6px;
      cursor: pointer;
    }
    &__image {
      img {
        width: auto;
        height: auto;
        max-width: 560px;
        max-height: 636px;
        min-width: 300px;
        min-height: 300px;
      }
    }
  }
  &__inner-wrapper {
    min-height: 100%;
  }
  &__footer {
    width: 100%;
    padding-top: 24px;
    margin-bottom: 56px;
    margin-top: auto;
    border-top: $box-border;
    &__title {
      font-family: "Poppins";
      font-size: 1.6rem;
      font-weight: 600;
      color: $color-gray-6;
      margin-bottom: 16px;
    }
    &__form {
      .Button {
        margin-left: 12px;
      }
      &__reject {
        padding-top: 0;
        padding-right: 24px;
        border-right: $box-border-dotted;
        flex: 1;
        .Input {
          flex: 1;
          background: white;
        }
        .MuiOutlinedInput-multiline {
          padding: 14px 14px;
        }
        .MuiFormControl-root .MuiOutlinedInput-input {
          line-height: 20px !important;
        }
      }
      &__accept {
        padding-top: 0;

        .DateInput {
          flex: 1;
          input {
            background: white;
          }
        }
        margin-left: 24px;
        width: 362px;
      }
    }
  }
}

@media (max-width: 1439px) {
  .DocDetail {
    &__body {
      max-width: 740px;
      box-sizing: border-box;
    }
    .DocAttachment {
      &:nth-child(3n + 3) {
        margin-right: 16px;
      }
      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }
  }
}
