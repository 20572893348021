@import "./consts.scss";

.Add,
.Edit {
  width: 522px;

  &__header {
    padding: 20px 24px 24px 24px;
    border-bottom: $box-border;

    .MuiIconButton-root {
      position: absolute;
      top: 2px;
      right: 2px;
      svg {
        font-size: 20px;
        fill: $color-gray-6;
      }
    }
    &__title {
      height: 24px;
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      font-family: $poppins;
      color: $color-gray-6;
    }
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      height: 40px;
      &__description {
        height: 100%;
        margin-right: 12px;
        font-size: 13px;
        line-height: 20px;
        color: $color-gray-6;
      }
    }
  }

  &__body {
    overflow-y: scroll;
    height: calc(100vh - 117px);
  }

  &__item {
    position: relative;
    padding: 24px 24px 0;
    &.plus {
      overflow: visible;
      padding-top: 0;
    }
    &__row {
      display: flex;
      flex: 1 1 0px;
      justify-content: space-between;
      margin-bottom: 12px;
      & > div {
        flex-basis: 100%;
        &:last-child {
          margin-left: 12px;
        }
        &:first-child {
          margin: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__seccion-header {
      height: 19px;
      margin: 4px 0 20px 14px;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      font-family: $poppins;
      color: $color-gray-6;
      &.left-side {
        padding-left: 0;
      }
    }
    &__close {
      cursor: pointer;
      position: absolute;
      top: -14px;
      right: 4px;

      .MuiIconButton-root {
        position: absolute;
        top: 2px;
        right: 2px;
        svg {
          font-size: 20px;
          fill: $color-gray-6;
        }
      }
    }
  }

  &__divider {
    position: relative;
    left: -16px;
    width: 506px;
    height: 1px;
    margin: 24px 0;
    border-top: 1px solid $color-gray-eb;
  }

  &__icon {
    display: flex;
    justify-content: space-between;
    height: 76px;
    .url {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      margin-right: 12px;
      .title {
        flex-grow: 1;
        width: 100%;
        height: 19px;
        padding-left: 14px;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        text-align: left;
        font-family: $poppins;
        color: $color-gray-6;
      }
      .inputUrl {
        flex-grow: 1;
      }
    }
    .imageWrapper {
      display: flex;
      flex-grow: 0;
      justify-content: center;
      align-items: center;
      width: 76px;
      height: 76px;
      border: 1px solid $color-gray-e0;

      &.small {
        padding: 20px;
      }
      &.large {
        padding: 5px;
      }
      img {
        width: 100%;
        height: 100%;
      }
      &.null {
        background-color: $color-gray-fa;
        img {
          width: 28px;
          height: 32px;
          margin: 0 auto;
        }
      }
    }
  }

  &__add-btn {
    margin-top: 20px;
    margin-left: 24px;
  }

  .MuiFormControl-root .MuiOutlinedInput-input {
    height: 32px;
    padding: 0 14px;
    line-height: 32px;
  }
  .MuiSelect-iconOutlined {
    top: 9px !important;
    right: 10px !important;
    transform: scale(1.7) !important;
  }
  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 11px) scale(1);
  }
}

.Add {
  &__header {
    padding: 20px 24px 24px 24px;
    border-bottom: $box-border;
  }
}

.Edit {
  &__header {
    padding: 32px 36px 29px 36px;
    border-bottom: none;
    &__content .Button {
      position: relative;
      top: -12px;
    }
  }
  &__tabs {
    width: 100%;
    &__item {
      flex: 1;
      height: 32px;
      border-bottom: 1px solid $color-gray-eb;
      font-weight: 600;
      font-size: 13px;
      line-height: 32px;
      font-family: $poppins;
      text-align: center;
      color: $color-gray-c7;
      cursor: default;
      &.active {
        border-bottom: 1px solid $color-primary;
        color: $color-primary;
      }
    }
  }
}
