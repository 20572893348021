/* CONSTS */

// google-fonts

@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);

// font-variables

$poppins: "Poppins", sans-serif;

@mixin font-poppins($weight, $size, $line-height) {
  font-style: normal;
  font-weight: $weight;
  font-stretch: normal;
  font-size: $size;
  line-height: $line-height;
  font-family: $poppins;
}

$open-sans: "Open Sans", sans-serif;

@mixin font-open-sans($weight, $size, $line-height) {
  font-style: normal;
  font-weight: $weight;
  font-stretch: normal;
  font-size: $size;
  line-height: $line-height;
  font-family: $open-sans;
}

// ui-components-info

$menu-expanded-width: 240px;
$menu-collapsed-width: 80px;

// Breakpoints
$screen-breakpoints: (
  "ul": 1920,
  "xl": 1366,
  "lg": 1024,
  "md": 768,
  "sm": 576,
  "xs": 425,
  "us": 375,
);

$breakpoint-tablet: 768px;
$breakpoint-desktop: 1199px;

// Zindex
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 100000 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-spinner: 1080 !default;

// Grid
$gutter: 4px;
$grid-gutter-width: $gutter * 4; // 16px
$grid-gutter-height: $gutter * 7; // 28px
$grid-column-mininum-width: $gutter * 36; // 144px

// Blocks
$sidebar-width: $gutter * 45; // 180px
$sidebar-width-collapsed: $gutter * 16; // 64px
$topbar-height: $gutter * 12; // 48px

// Weights
$open-sans-regular: 400;
$open-sans-semibold: 600;
$open-sans-bold: 700;

// Colors

$color-primary: #ec754a;
$color-info: #3584da;
$color-warning: #e28f01;
$color-success: #24a453;
$color-error: #fa3d3d;

$color-red: #fa3d3d;
$color-red2: #ec0000;
$color-red3: #ef4c52;
$color-red4: #ed5a5f;

$color-orange: #ec754a;
$color-orange2: #bd8566;
$color-orange-light: #f0916e;
$color-orange-dark: #ca613c;
$color-orange-dark2: #3e261e;
$color-orange-dark3: #593426;
$color-orange-opacity6: #fcf7f3;
$color-orange-item-selected: #fef4f1;

$color-yellow: #dcac21;

$color-green: #24a453;
$color-green2: #a1b24e;
$color-green3: #45a225;
$color-green4: #0d9e78;

$color-blue: #3584da;
$color-blue1: #2598e4;
$color-blue2: #069ead;
$color-blue3: #5d7eb6;
$color-blue-light: #f6f9fb;
$color-blue-light2: #e5eff5;
$color-blue-light3: #f0f5f8;
$color-blue-dark: #134863;
$color-blue-dark2: #1b53a6;

$color-purple: #dd7ed0;
$color-purple2: #9989dc;

$color-black: #000;
$color-gray-14: #141414;
$color-gray-26: #262626;
$color-gray-3: #333;
$color-gray-4d: #4d4d4d;
$color-gray-6: #666;
$color-gray-9: #999;
$color-gray-b3: #b3b3b3;
$color-gray-c: #ccc;
$color-gray-c7: #c7c7c7;
$color-gray-e0: #e0e0e0;
$color-gray-eb: #ebebeb;
$color-gray-f2: #f2f2f2;
$color-gray-f5: #f5f5f5;
$color-gray-f7: #f7f7f7;
$color-gray-fa: #fafafa;
$color-white: #fff;

// ServiceStatus Colors

$color-auctioning: #b49b41;
$color-assigned: #8ea07d;

$color-queued: #0d9e78;
$color-scheduled: #5d7eb6;
$color-pending-border: #c7c7c7;
$color-pending-background: #e0e0e0;
$color-pending-color: #999;
$color-planned: #bd8566;
$color-accepted: #a1b24e;
$color-started: #dcac21;
$color-located: #069ead;
$color-inris: #2598e4;
$color-loaded: #dd7ed0;
$color-completed: #ec754a;
$color-finished: #666;
$color-rejected: #9989dc;
$color-canceled: #ef4c52;

$input-disabled: $color-gray-eb;
$button-disabled: $color-gray-eb;
$button-disabled-font: $color-gray-9;
$button-primary-font: $color-white;
$button-primary: $color-blue;
$button-primary-hover: #2369d2;
$button-primary-pressed: #1b53a6;
$button-secondary-font: $color-gray-4d;
$button-secondary: $color-gray-eb;
$button-secondary-pressed: #dbdbdb;
$button-white: $color-white;
$button-white-border: $color-gray-c;
$button-white-font: $color-gray-4d;
$button-white-pressed-font: $color-blue;

$box-border: 1px solid $color-gray-eb;
$box-border-dotted: 1px solid $color-gray-eb;
$box-border-hover: 1px solid $color-gray-9;
$box-border-focus: 2px solid $color-orange;

$body-background: #f6f9fc;
$background-translucent-light: rgba(255, 255, 255, 0.5); // opacity 0.4 (Zeplin)
$background-translucent-dark: rgba(0, 0, 0, 0.2);

$side-panel-background: rgba(0, 0, 0, 0.5);
$preview-background: rgba(0, 0, 0, 0.05);
$close-confirmation-background: rgba(0, 0, 0, 0.05);
$pending-doc-background: rgba(0, 0, 0, 0.1);
$financial-modal-background: rgba(0, 0, 0, 0.5);
$modal-background: rgba(0, 0, 0, 0.1);

$row-hover: #e2edf4;
$row-even: #f6f9fb;
$row-selected: #fff8cf;
