@import "../../../styles/consts.scss";

.MapView {
  flex: 1;
  &__map {
    width: 100%;
    height: 100%;
  }
  .marker-label {
    transform: translateX(-50%) !important;
  }
  &__markerlabel {
    font-size: 10;
    font-weight: bold;
    padding: 4px;
    color: white;
    text-transform: uppercase;
    &--completed {
      background: $color-primary;
    }
    &--loaded {
      background: $color-purple;
    }
    &--inris {
      background: $color-blue1;
    }
    &--located {
      background: $color-blue2;
    }
    &--started {
      background: $color-yellow;
    }
    &--accepted {
      background: $color-green2;
    }
    &--available {
      background: $color-green;
    }
    &--unavailable {
      background: $color-gray-b3;
    }
  }
  &__cluster {
    span {
      color: white !important;
      font-family: "Open Sans";
      font-weight: bold;
      font-size: 1.1rem;
    }
  }
}
.MapWindow {
  width: 180px;
  font-family: "Open Sans";
  &__avatar {
    width: 24px;
    height: 24px;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 100%;

    img {
      width: 100%;
      height: auto;
    }

    div {
      width: 100%;
      height: 100%;
      color: rgb(69, 162, 37);
      background: rgba(69, 162, 37, 0.1);
    }
  }
  &__header {
    // margin-bottom: 4px;
    &__name {
      font-weight: 600;
      font-size: 1.1rem;
    }
    &__tag {
      margin-left: 8px;
      background: $color-gray-f2;
      padding: 0 4px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.9rem;
    }
  }
  &__subheader {
    font-size: 1.1rem;
    &__type {
      color: $color-gray-9;
      margin-right: 8px;
      text-transform: capitalize;
    }
    &__plate {
      color: $color-gray-9;
      text-transform: uppercase;
    }
    &__tag {
      color: $color-gray-6;
      margin-left: 8px;
      background: $color-gray-fa;
      padding: 0 4px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.9rem;
    }
  }
  &__body {
    font-size: 11px;
    margin-top: 10px;
    padding-top: 10px;
    font-weight: normal;
    border-top: $box-border;
  }
  &__row {
    margin-bottom: 4px;
    img {
      margin-right: 16px;
    }
    & > div {
      width: calc(100% - 28px);
    }
  }
  &__address {
    margin-top: 10px;
    padding-top: 10px;
    border-top: $box-border-dotted;
    .MapWindow__row {
      align-items: self-start;
      &:first-child {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          border-left: $box-border;
          border-color: $color-gray-b3;
          height: 50%;
          width: 1px;
          top: 20px;
          left: 6px;
          z-index: -1;
        }
      }
      img {
        margin-top: 4px;
      }
    }
  }
}
