@import "../../styles/consts.scss";

.UserPanel {
  &__header {
    .MuiIconButton-root {
      position: absolute;
      top: 2px;
      right: 2px;
      svg {
        font-size: 20px;
        fill: $color-gray-6;
      }
    }
  }
}

#Users {
  .SearchInput {
    position: relative;
    width: 325px !important;
    background: $color-white;
  }
  .UserList__avatar {
    & > img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
    & > div {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      @include font-poppins(600, 11.7px, 28px);
      letter-spacing: -0.16px;
      text-align: center;
      &.admin {
        background-color: #ffebeb;
        color: #fa3d3d;
      }
      &.office {
        background-color: #eaf3fb;
        color: #3584da;
      }
      &.driver {
        background-color: #ecf6e9;
        color: #45a225;
      }
      &.nexus {
        background-color: #efefef;
        color: #666666;
      }
    }
  }
}

body.menu-expanded {
  @media (max-width: 1279px) {
    #Users .ListBody {
      .MuiTableCell-stickyHeader,
      .MuiTableCell-root.MuiTableCell-body {
        &:nth-child(9) {
          width: 24px !important;
          max-width: 24px !important;
        }
      }
    }
  }
}

body.menu-collapsed {
  @media (max-width: 1119px) {
    #Users .ListBody {
      .MuiTableCell-stickyHeader,
      .MuiTableCell-root.MuiTableCell-body {
        &:nth-child(9) {
          width: 24px !important;
          max-width: 24px !important;
        }
      }
    }
  }
}
