/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.Button {
  height: 32px;
  padding: 0 16px;
  background: #ec754a;
  color: white;
  font-size: 11px;
  font-weight: 600;
  font-family: "Poppins";
  border: none;
  border-radius: 3px; }
  .Button:hover {
    background: #f0916e; }
  .Button:active {
    background: #ca613c; }
  .Button:disabled {
    background: #ebebeb;
    color: #999;
    pointer-events: none; }
  .Button__prefixIcon {
    margin-right: 7px; }

.Button--secondary.Button {
  background: white;
  color: #666;
  border: 1px solid #e0e0e0; }
  .Button--secondary.Button:hover {
    border: 1px solid #c7c7c7; }
  .Button--secondary.Button:active {
    border: 1px solid #c7c7c7;
    background: #f2f2f2; }
  .Button--secondary.Button:disabled {
    background: #ebebeb;
    color: #999;
    pointer-events: none; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.CircleChart {
  position: relative;
  height: 138px !important;
  border-radius: 50%;
  border: 1px solid #ebebeb; }
  .CircleChart__data {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center; }
    .CircleChart__data__title {
      font-size: 4rem;
      font-family: "Poppins";
      font-weight: 300;
      line-height: 1;
      margin-bottom: 6px; }
    .CircleChart__data__description {
      font-size: 1.1rem;
      font-family: "Poppins";
      line-height: 1.18;
      color: #999; }
      .CircleChart__data__description .bold {
        color: #666; }

.pac-container {
  z-index: 10000 !important; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.SearchInput {
  position: relative;
  width: 283px;
  background: #fff; }
  .SearchInput .MuiFormControl-root .MuiOutlinedInput-root {
    padding: 0 8px; }
  .SearchInput .MuiFormControl-root .MuiOutlinedInput-input {
    height: 32px !important;
    padding: 0 !important;
    padding-left: 4px !important;
    font-size: 13px; }
  .SearchInput__tip {
    position: absolute;
    top: calc(100% + 2px);
    font-size: 10px;
    font-weight: 600;
    line-height: 1.6;
    color: #999; }

@media (max-width: 1279px) {
  body.menu-expanded .SearchInput {
    width: 230px !important; } }

@media (max-width: 1119px) {
  body.menu-collapsed .SearchInput {
    width: 230px !important; } }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.Select .MuiAutocomplete-root .MuiAutocomplete-endAdornment {
  top: auto; }

.Select .MuiSelect-select:focus {
  background: transparent; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.SelectBox {
  height: 56px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  box-sizing: border-box; }
  .SelectBox:hover {
    border: 1px solid #999; }
  .SelectBox--focus {
    border: 2px solid #ec754a !important; }
  .SelectBox__label {
    color: #999;
    font-size: 1.1rem;
    border-bottom: 1px solid #ebebeb;
    margin: 0 12px;
    padding: 5px 0; }
  .SelectBox__select {
    font-size: 1.1rem; }
    .SelectBox__select .MuiInputBase-root {
      padding: 0 12px !important;
      margin-top: 2px !important; }
      .SelectBox__select .MuiInputBase-root:before, .SelectBox__select .MuiInputBase-root:after {
        content: "";
        display: none; }
      .SelectBox__select .MuiInputBase-root .MuiSelect-select:focus {
        background: transparent; }
    .SelectBox__select .MuiSvgIcon-root {
      margin-right: 10px;
      margin-top: 4px; }
    .SelectBox__select .MuiAutocomplete-endAdornment {
      right: 7px; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.Select .MuiAutocomplete-root .MuiAutocomplete-endAdornment {
  top: auto; }

.Select .MuiSelect-select:focus {
  background: transparent; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.SwitchChip {
  position: relative;
  top: -2px; }

.SwitchInput.SwitchChip .MuiSwitch-root .MuiSwitch-track {
  border: none !important; }

.SwitchInput.SwitchChip .MuiSwitch-root .MuiButtonBase-root.MuiIconButton-root .MuiIconButton-label .MuiSwitch-thumb {
  background-color: white !important; }

.SwitchInput.SwitchChip .MuiSwitch-root .MuiButtonBase-root.MuiIconButton-root.Mui-disabled.Mui-checked + .MuiSwitch-track {
  opacity: .35 !important; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.cTable {
  box-shadow: none !important; }
  .cTable .MuiTableCell-root {
    box-sizing: border-box;
    height: 48px !important;
    border-bottom: 1px solid #e0e0e0;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    background-color: white; }
    .cTable .MuiTableCell-root.MuiTableCell-head {
      font-style: normal;
      font-weight: 500;
      font-stretch: normal;
      font-size: 11px;
      line-height: 1.27;
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;
      color: #666; }
    .cTable .MuiTableCell-root.MuiTableCell-body {
      font-style: normal;
      font-weight: 400;
      font-stretch: normal;
      font-size: 13px;
      line-height: 1.54;
      font-family: "Open Sans", sans-serif;
      color: #4d4d4d; }
      .cTable .MuiTableCell-root.MuiTableCell-body .Button {
        height: 24px !important;
        padding-right: 10px;
        padding-left: 10px;
        font-style: normal;
        font-weight: 600;
        font-stretch: normal;
        font-size: 11px;
        line-height: normal;
        font-family: "Poppins", sans-serif;
        color: #4d4d4d; }
        .cTable .MuiTableCell-root.MuiTableCell-body .Button:disabled {
          color: #999; }
  .cTable .MuiTypography-body2 {
    font-family: "Open Sans", sans-serif;
    font-size: 1.3rem;
    color: #666; }
  .cTable .MuiTablePagination-root {
    border-top: 1px solid #ebebeb; }
  .cTable .MuiTablePagination-toolbar.MuiToolbar-gutters {
    padding-right: 12px;
    height: 48px; }
  .cTable .MuiTablePagination-root .MuiToolbar-root {
    display: flex;
    position: relative;
    align-items: center; }
  .cTable .MuiTablePagination-root
.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters
.MuiBox-root {
    flex-shrink: 0 !important;
    flex-basis: 156px !important;
    width: 156px !important;
    min-width: 156px !important;
    margin-left: 20px !important; }
  .cTable .MuiTablePagination-toolbar {
    height: 48px !important;
    min-height: 48px !important; }

div#menu- > div > ul > li.MuiTablePagination-menuItem:first-child {
  display: flex !important; }

.cTable .MuiTablePagination-root .MuiToolbar-root {
  background-color: #fff !important; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.Chip {
  height: 24px;
  padding: 0 12px;
  color: #666;
  font-weight: 600;
  font-size: 1.1rem;
  background: white;
  border: 1px solid white;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease-in, border 0.2s ease-in, color 0.2s ease-in; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.RadioGroupWrapper {
  position: relative;
  flex: 0;
  height: 100%;
  min-width: 120px !important;
  margin-top: 4px !important;
  margin-right: 35px !important; }
  .RadioGroupWrapper .RadioGroupLabel {
    height: 16px;
    min-width: 120px !important;
    margin: -28px 0 0 0;
    font-family: "Open Sans";
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    color: #999; }
  .RadioGroupWrapper:first-child {
    min-width: 104px !important; }
    .RadioGroupWrapper:first-child .RadioGroupLabel {
      min-width: 104px !important; }
  .RadioGroupWrapper .RadioGroup {
    display: flex;
    width: 100%;
    margin-right: 12px;
    justify-content: space-between; }
    .RadioGroupWrapper .RadioGroup .RadioItemLabel {
      height: 20px;
      margin: 0; }
      .RadioGroupWrapper .RadioGroup .RadioItemLabel:last-child {
        margin-top: 4px; }
      .RadioGroupWrapper .RadioGroup .RadioItemLabel > span:last-child {
        padding-right: 18px;
        font-family: "Open Sans";
        font-size: 13px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        color: #4d4d4d; }
    .RadioGroupWrapper .RadioGroup .RadioItemButton {
      width: 12px;
      height: 12px;
      margin: 5px 8px 3px 0;
      padding: 2.5px;
      background-color: #fff;
      color: #999; }
      .RadioGroupWrapper .RadioGroup .RadioItemButton.Mui-checked {
        color: #ec754a; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.PageWrapper {
  position: relative;
  height: 100%; }
  .PageWrapper.hide {
    display: none !important; }

.FullPagePanel {
  flex-wrap: nowrap;
  position: absolute;
  top: -40px;
  right: -40px;
  bottom: -40px;
  left: -40px;
  z-index: 9;
  min-width: 100%;
  min-height: 100%;
  background-color: #fafafa !important; }

@media (max-width: 1023px) {
  body.menu-expanded .PageWrapper {
    display: none !important; } }

@media (max-width: 863px) {
  body.menu-collapsed .PageWrapper {
    display: none !important; } }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.PageHeader {
  flex-wrap: nowrap !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  box-sizing: content-box;
  width: calc(100% - 80px);
  height: 40px;
  padding: 24px 40px;
  box-shadow: 4px 2px 10px 0 rgba(124, 124, 124, 0.04), 4px 4px 7px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff; }
  .PageHeader__goBack {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    cursor: pointer; }
  .PageHeader__title {
    height: 28px !important;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    font-size: 20px;
    line-height: normal;
    font-family: "Poppins", sans-serif;
    line-height: 28px !important;
    text-transform: uppercase;
    color: #666; }
  .PageHeader__filters {
    display: flex;
    position: relative; }
    .PageHeader__filters .Button {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 12px;
      font-style: normal;
      font-weight: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: 32px;
      font-family: "Open Sans", sans-serif; }
      .PageHeader__filters .Button img {
        margin-right: 10px; }
        .PageHeader__filters .Button img:nth-child(2) {
          margin-right: 0;
          margin-left: 16px;
          margin-top: 2px; }
      .PageHeader__filters .Button:first-child {
        margin-right: 16px;
        margin-left: 40px; }
    .PageHeader__filters__dropdown {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      position: absolute !important;
      top: 38px !important;
      left: 40px;
      z-index: 1001;
      width: auto;
      border-radius: 3px;
      padding: 24px 12px 12px 24px;
      box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.07), 1px 1px 6px 0 rgba(0, 0, 0, 0.12);
      background: #fff; }
      .PageHeader__filters__dropdown .Chip {
        margin-right: 12px;
        margin-bottom: 12px; }
    .PageHeader__filters .dateFilter {
      margin-right: 0 !important;
      margin-left: 0 !important; }
      .PageHeader__filters .dateFilter .Button {
        margin-right: 0 !important;
        margin-left: 0 !important; }
  .PageHeader__actions {
    position: relative; }
    .PageHeader__actions__search {
      position: relative;
      top: 0; }
    .PageHeader__actions__lastButton {
      margin-left: 24px !important; }
  .PageHeader__info {
    height: 20px;
    margin-left: 40px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    font-size: 13px;
    line-height: 1.54;
    font-family: "Open Sans", sans-serif;
    color: #666; }
  .PageHeader__mainActionBtn {
    margin-left: 24px; }
  .PageHeader.ListHeader {
    height: 32px;
    padding: 38px 40px 30px;
    background-color: #fafafa;
    box-shadow: none; }
  .PageHeader .react-datepicker__day {
    font-weight: 600;
    font-family: 'Open Sans';
    font-size: 1.2rem;
    width: 32px;
    height: 32px;
    line-height: 32px;
    color: #4d4d4d; }
  .PageHeader .react-datepicker__day--outside-month {
    color: #c7c7c7; }
  .PageHeader .react-datepicker__day--in-selecting-range,
  .PageHeader .react-datepicker__day--in-range {
    background-color: #f2f2f2;
    color: #4d4d4d; }
  .PageHeader .react-datepicker__day--selected,
  .PageHeader .react-datepicker__day--range-start,
  .PageHeader .react-datepicker__day--range-end {
    background-color: #ec754a;
    color: white; }
  .PageHeader .react-datepicker__day-names {
    margin-top: 20px; }
  .PageHeader .react-datepicker__day-name {
    width: 32px;
    font-family: 'Open Sans';
    color: #666;
    font-size: 1.1rem; }
  .PageHeader .react-datepicker__header {
    border: none;
    background: white; }
  .PageHeader .react-datepicker__month {
    margin-top: 12px; }
  .PageHeader .react-datepicker {
    border: none; }
  .PageHeader .react-datepicker__current-month {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.5rem;
    color: #666; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.PageBody {
  width: 100%;
  max-width: 1280px !important;
  margin: 104px auto 0;
  padding: 0 40px; }
  .PageBody.ListBody {
    min-width: 100% !important;
    height: 100% !important;
    margin: 100px 0 40px;
    background-color: #fafafa; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.CardRowWrapper {
  max-width: 1120px;
  padding-bottom: 16px; }
  .CardRowWrapper__header {
    flex-grow: 1;
    width: 100%;
    height: 48px;
    margin: 0 !important; }
    .CardRowWrapper__header .title {
      flex-grow: 1;
      height: 32px;
      font-style: normal;
      font-weight: 600;
      font-stretch: normal;
      font-size: 20px;
      line-height: 32px;
      font-family: "Poppins", sans-serif;
      text-align: left;
      text-transform: uppercase;
      color: #666; }
    .CardRowWrapper__header .info {
      margin-left: 12px;
      font-style: normal;
      font-weight: 600;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: "Open Sans", sans-serif;
      color: #666; }
    .CardRowWrapper__header .buttons .Button {
      margin-right: 16px; }
      .CardRowWrapper__header .buttons .Button:last-child {
        margin-right: 0; }

@media (max-width: 1279px) {
  body.menu-expanded .CardRowWrapper {
    flex-direction: column; }
    body.menu-expanded .CardRowWrapper .Card {
      margin-right: 0 !important;
      margin-bottom: 16px; }
      body.menu-expanded .CardRowWrapper .Card:last-child {
        margin-bottom: 0; } }

@media (max-width: 899px) {
  body.menu-expanded .PageHeader {
    min-width: 659px; }
  body.menu-expanded .PageBody {
    min-width: 659px; }
  body.menu-expanded .CardRowWrapper {
    min-width: 579px !important; } }

@media (max-width: 1119px) {
  body.menu-collapsed .CardRowWrapper {
    flex-direction: column; }
    body.menu-collapsed .CardRowWrapper .Card {
      margin-right: 0 !important;
      margin-bottom: 16px; }
      body.menu-collapsed .CardRowWrapper .Card:last-child {
        margin-bottom: 0; } }

@media (max-width: 739px) {
  body.menu-collapsed .PageHeader {
    min-width: 659px; }
  body.menu-collapsed .PageBody {
    min-width: 659px; }
  body.menu-collapsed .CardRowWrapper {
    min-width: 579px !important; } }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.Card {
  flex: 1 0;
  min-height: 176px;
  max-height: 176px;
  margin-right: 16px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  padding: 0;
  background-color: #fff; }
  .Card:last-child {
    margin-right: 0; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.CardColumnIcon {
  position: relative;
  overflow: visible;
  box-sizing: content-box;
  width: 104px;
  max-width: 104px;
  height: calc(100% - 16px);
  margin: 16px 24px 0; }
  .CardColumnIcon__title {
    flex-grow: 0;
    width: 200px;
    height: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    font-size: 14px;
    line-height: 14px;
    font-family: "Poppins", sans-serif;
    color: #666; }
  .CardColumnIcon__icon {
    flex-grow: 1;
    position: relative;
    max-width: 104px;
    text-align: center; }
    .CardColumnIcon__icon__label {
      margin-top: 2px;
      font-style: normal;
      font-weight: 600;
      font-stretch: normal;
      font-size: 11px;
      line-height: 1.45;
      font-family: "Open Sans", sans-serif;
      color: #4d4d4d; }
  .CardColumnIcon__info {
    margin-top: 8px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    font-size: 11px;
    line-height: 1.45;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    color: #999; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.CardColumnTable {
  flex-grow: 1;
  box-sizing: content-box;
  height: 100%;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 13px;
  line-height: 1.54;
  font-family: "Poppins", sans-serif;
  color: #4d4d4d; }
  .CardColumnTable .MuiTableContainer-root {
    height: 100%; }
  .CardColumnTable .MuiTableHead-root .MuiTableCell-root {
    padding: 16px 0 16px;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
    font-size: 11px;
    line-height: normal;
    font-family: "Poppins", sans-serif;
    color: #666; }
  .CardColumnTable .MuiTableHead-root .MuiTableRow-root :first-child {
    max-width: 25%;
    padding-left: 24px !important; }
  .CardColumnTable .MuiTableHead-root .MuiTableRow-root :last-child {
    padding-right: 24px; }
  .CardColumnTable .MuiTableBody-root .MuiTableCell-root {
    padding: 4px 0 7px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    font-size: 13px;
    line-height: 1.54;
    font-family: "Open Sans", sans-serif;
    color: #4d4d4d; }
    .CardColumnTable .MuiTableBody-root .MuiTableCell-root .amount {
      display: inline-block;
      width: 100%;
      padding: 0 !important;
      text-align: right; }
      .CardColumnTable .MuiTableBody-root .MuiTableCell-root .amount .decimals {
        padding: 0 !important; }
  .CardColumnTable .MuiTableBody-root .MuiTableRow-root :first-child {
    padding-left: 24px !important; }
  .CardColumnTable .MuiTableBody-root .MuiTableRow-root :last-child {
    padding-right: 24px; }
  .CardColumnTable .MuiTableCell-root.MuiTableCell-body {
    height: 31px !important; }
  .CardColumnTable .MuiTablePagination-root {
    display: none; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.CardColumnText {
  flex-grow: 1;
  box-sizing: content-box;
  height: calc(100% - 32px);
  margin: 16px 24px 16px 0;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 13px;
  line-height: 1.54;
  font-family: "Open Sans", sans-serif;
  color: #4d4d4d; }
  .CardColumnText > div {
    margin-bottom: 8px; }
    .CardColumnText > div:last-child {
      margin-bottom: 0; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.ModalWindow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000 !important;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1); }
  .ModalWindow__box {
    position: relative;
    width: 347px;
    border-radius: 3px;
    padding: 12px 24px 24px;
    background: #fff;
    box-shadow: 0 8px 19px 0 rgba(0, 0, 0, 0.1), 10px 19px 46px 0 rgba(0, 0, 0, 0.07), 20px 35px 38px 0 rgba(0, 0, 0, 0.07); }
    .ModalWindow__box:last-child {
      margin-top: 24px !important; }
  .ModalWindow__title {
    margin-bottom: 28px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    font-size: 16px;
    line-height: 1.5;
    font-family: "Poppins", sans-serif;
    color: #666; }
  .ModalWindow__text {
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    font-size: 13px;
    line-height: 1.54;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    color: #4d4d4d;
    margin: 0 16px 16px; }
    .ModalWindow__text.Bold {
      font-weight: 600; }
  .ModalWindow__actions {
    margin-top: 24px !important; }
    .ModalWindow__actions > button:first-child {
      margin-right: 12px; }
    .ModalWindow__actions .Button {
      margin-top: 0 !important; }

.ModalWindow.cancelBtnDisabled .ModalWindow__actions > .Button.Button--secondary {
  display: none; }

.react-datepicker {
  border: 2px solid black; }

.react-datepicker__day {
  font-weight: 600;
  font-family: "Open Sans";
  font-size: 1.2rem;
  width: 32px;
  height: 32px;
  line-height: 32px;
  color: #4d4d4d; }

.react-datepicker__day--outside-month {
  color: #c7c7c7; }

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #f2f2f2;
  color: #4d4d4d; }

.react-datepicker__day--selected,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: #ec754a;
  color: white; }

.react-datepicker__day-names {
  margin-top: 20px; }

.react-datepicker__day-name {
  width: 32px;
  font-family: "Open Sans";
  color: #666;
  font-size: 1.1rem; }

.react-datepicker__header {
  border: none;
  background: white; }

.react-datepicker__month {
  margin-top: 12px; }

.react-datepicker {
  border: none; }

.react-datepicker__current-month {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.5rem;
  color: #666; }

body.menu-expanded .ModalWindow__box {
  left: 120px !important; }

body.menu-collapsed .ModalWindow__box {
  left: 40px !important; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.Panel {
  width: 522px; }
  .Panel__header {
    border-bottom: 1px solid #ebebeb;
    padding: 20px 24px 24px; }
    .Panel__header__title {
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 600;
      font-stretch: normal;
      font-size: 1.8rem;
      line-height: normal;
      font-family: "Poppins", sans-serif;
      color: #666; }
    .Panel__header__actions {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      line-height: 1.54; }
    .Panel__header__description {
      margin-right: 66px;
      font-size: 1.3rem;
      color: #666; }
  .Panel__body {
    overflow-y: scroll;
    height: calc(100vh - 117px);
    padding-bottom: 40px; }
  .Panel__item {
    position: relative;
    border-bottom: 1px solid #ebebeb;
    padding: 20px; }
    .Panel__item__close {
      position: absolute;
      top: 4px;
      right: 4px;
      cursor: pointer; }
    .Panel__item__row {
      display: flex;
      flex: 1 1 0px;
      justify-content: space-between;
      margin-bottom: 12px; }
      .Panel__item__row > div {
        flex-basis: 100%; }
        .Panel__item__row > div:first-child {
          margin-right: 12px; }
      .Panel__item__row:last-child {
        margin-bottom: 0; }
  .Panel__add {
    margin-top: 20px;
    margin-left: 24px; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.ListBody {
  flex-direction: column;
  height: calc(100vh - 140px); }
  .ListBody .MuiPaper-root {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    padding: 0;
    background: #fff; }
    .ListBody .MuiPaper-root .MuiTableContainer-root {
      flex-grow: 1;
      width: 100%;
      min-width: 100%;
      height: calc(100vh - 191px) !important;
      min-height: calc(100vh - 191px) !important; }
    .ListBody .MuiPaper-root .MuiTableCell-stickyHeader,
    .ListBody .MuiPaper-root .MuiTableCell-root.MuiTableCell-body {
      flex-grow: 1;
      box-sizing: content-box;
      padding-right: 8px;
      padding-left: 8px;
      text-align: left; }
      .ListBody .MuiPaper-root .MuiTableCell-stickyHeader:first-child,
      .ListBody .MuiPaper-root .MuiTableCell-root.MuiTableCell-body:first-child {
        padding-left: 24px; }
      .ListBody .MuiPaper-root .MuiTableCell-stickyHeader:last-child,
      .ListBody .MuiPaper-root .MuiTableCell-root.MuiTableCell-body:last-child {
        padding-right: 24px; }
      .ListBody .MuiPaper-root .MuiTableCell-stickyHeader.MuiTableCell-alignLeft,
      .ListBody .MuiPaper-root .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
        text-align: left; }
      .ListBody .MuiPaper-root .MuiTableCell-stickyHeader.MuiTableCell-alignCenter,
      .ListBody .MuiPaper-root .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter {
        text-align: center; }
      .ListBody .MuiPaper-root .MuiTableCell-stickyHeader.MuiTableCell-alignRight,
      .ListBody .MuiPaper-root .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight {
        text-align: right; }
    .ListBody .MuiPaper-root .MuiTablePagination-root {
      box-sizing: border-box;
      flex-grow: 0;
      overflow: hidden;
      width: 100%;
      height: 49px;
      max-height: 49px;
      font-style: normal;
      font-weight: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: 1.54;
      font-family: "Open Sans", sans-serif;
      color: #666; }
      .ListBody .MuiPaper-root .MuiTablePagination-root .MuiTablePagination-input {
        position: relative;
        margin-right: 10px; }
        .ListBody .MuiPaper-root .MuiTablePagination-root .MuiTablePagination-input .MuiSelect-selectMenu {
          width: 24px;
          margin-left: 5px;
          border: 1px solid #ebebeb;
          border-radius: 3px;
          background: #fff;
          text-align: center; }
          .ListBody .MuiPaper-root .MuiTablePagination-root .MuiTablePagination-input .MuiSelect-selectMenu:focus {
            background: #fff; }
        .ListBody .MuiPaper-root .MuiTablePagination-root .MuiTablePagination-input .MuiSelect-icon {
          position: relative;
          top: 0;
          left: -25px;
          width: 24px;
          height: 24px; }

@media (max-width: 1279px) {
  body.menu-expanded .ListBody .MuiTableCell-stickyHeader.hideable,
  body.menu-expanded .ListBody .MuiTableCell-root.MuiTableCell-body.hideable {
    display: none !important; }
  body.menu-expanded .ListBody .MuiTableBody-root .ShortenButton {
    width: 24px !important;
    max-width: 24px !important;
    height: 24px !important;
    max-height: 24px !important;
    margin: 0 !important;
    padding: 0 !important; }
    body.menu-expanded .ListBody .MuiTableBody-root .ShortenButton .Button__prefixIcon {
      margin: 0 !important;
      padding: 0 !important; }
    body.menu-expanded .ListBody .MuiTableBody-root .ShortenButton span {
      display: none; } }

@media (max-width: 1119px) {
  body.menu-collapsed .ListBody .MuiTableCell-stickyHeader.hideable,
  body.menu-collapsed .ListBody .MuiTableCell-root.MuiTableCell-body.hideable {
    display: none !important; }
  body.menu-collapsed .ListBody .MuiTableBody-root .ShortenButton {
    width: 24px !important;
    max-width: 24px !important;
    height: 24px !important;
    max-height: 24px !important;
    margin: 0 !important;
    padding: 0 !important; }
    body.menu-collapsed .ListBody .MuiTableBody-root .ShortenButton .Button__prefixIcon {
      margin: 0 !important;
      padding: 0 !important; }
    body.menu-collapsed .ListBody .MuiTableBody-root .ShortenButton span {
      display: none; } }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.chartMongoDB {
  display: inline-block;
  position: relative;
  z-index: 0 !important;
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  border-radius: 3px !important; }
  .chartMongoDB > div {
    position: relative;
    width: 100% !important;
    height: 100% !important; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
#DateFilter {
  position: absolute;
  top: 38px;
  width: 415px;
  height: 360px;
  margin-left: 0;
  border-radius: 3px;
  padding: 0;
  box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.07), 1px 1px 6px 0 rgba(0, 0, 0, 0.12);
  background: #fff; }
  #DateFilter .DateFilterOptions > li {
    width: 134px;
    height: 36px;
    border-top: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    border-bottom: none !important;
    padding: 0 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    font-size: 11px;
    line-height: 36px;
    font-family: "Open Sans", sans-serif;
    color: #4d4d4d;
    cursor: pointer; }
    #DateFilter .DateFilterOptions > li:first-child {
      border-top: none; }
  #DateFilter .DateFilterOptions > li:hover {
    background-color: #f5f5f5 !important; }
  #DateFilter .DateFilterOptions > li.selected {
    border-left: 2px solid #ec754a;
    padding-left: 12px;
    background-color: #fef4f1 !important; }
  #DateFilter .DateFilterOptions > li.selected:hover {
    border-left: 2px solid #ec754a;
    background-color: #fef4f1 !important; }
  #DateFilter .DateFilterCalendarAndButton {
    padding: 11px;
    padding-right: 13px; }
  #DateFilter .Button {
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 70px;
    max-width: 70px !important;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-weight: 600;
    text-align: center; }
    #DateFilter .Button span {
      width: 100%;
      text-align: center; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.Spinner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  height: var(--vh100);
  background: rgba(255, 255, 255, 0.5); }
  .Spinner__image {
    width: 6.4rem;
    height: 6.4rem; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.SwitchBox {
  width: auto;
  height: 40px;
  padding: 12px;
  padding-left: 16px;
  border: 1px solid #ebebeb;
  border-radius: 3px; }
  .SwitchBox__label {
    margin-right: 12px;
    color: #666;
    font-size: 1.2rem;
    font-weight: 500; }

.TimelineChart__dot {
  box-shadow: none; }

/* CONSTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,700);
.Form {
  display: flex;
  flex-direction: column;
  width: 100%; }

.CardRowWrapper {
  width: 100%;
  max-width: 1120px !important;
  margin: 0 auto 16px !important; }
  .CardRowWrapper .Card {
    width: 100%;
    max-width: 1120px !important;
    min-height: 176px !important;
    max-height: 100% !important; }
    .CardRowWrapper .Card .CardColumnIcon__title {
      text-transform: uppercase; }
    .CardRowWrapper .Card .CardColumnIcon img {
      top: 38px;
      left: 24px;
      width: 56px;
      height: 56px; }
    .CardRowWrapper .Card .CardColumnForm {
      width: calc(100% - 184px);
      margin-top: 50px;
      margin-bottom: 45px; }
      .CardRowWrapper .Card .CardColumnForm .Row {
        width: 100%;
        margin-left: 0;
        margin-bottom: 12px; }
        .CardRowWrapper .Card .CardColumnForm .Row .Column {
          flex-grow: 1;
          margin-right: 12px; }
          .CardRowWrapper .Card .CardColumnForm .Row .Column:last-child {
            margin-right: 0; }
          .CardRowWrapper .Card .CardColumnForm .Row .Column.half {
            display: flex;
            flex-direction: row;
            flex: 1 0;
            gap: 12px; }
            .CardRowWrapper .Card .CardColumnForm .Row .Column.half > * {
              flex: 1 0; }
          .CardRowWrapper .Card .CardColumnForm .Row .Column.add-btn {
            flex-grow: 0;
            width: 34px;
            max-width: 34px; }
            .CardRowWrapper .Card .CardColumnForm .Row .Column.add-btn .Button {
              width: 34px;
              max-width: 34px;
              height: 32px;
              padding: 0 8px !important; }
          .CardRowWrapper .Card .CardColumnForm .Row .Column .MuiInputLabel-outlined.MuiInputLabel-shrink {
            transform: translate(14px, -4px) scale(0.75);
            background: #fff !important; }
          .CardRowWrapper .Card .CardColumnForm .Row .Column .MuiFormHelperText-root.Mui-error {
            position: absolute;
            top: 26px;
            left: 0; }
          .CardRowWrapper .Card .CardColumnForm .Row .Column .MuiSelect-iconOutlined {
            top: 9px !important;
            right: 10px !important;
            transform: scale(1.7) !important;
            /*
            top: 4px !important;
            right: 4px !important;
            width: 24px !important;
            height: 24px !important;
            fill: $color-gray-9;
            */ }

div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  margin-left: 0 !important; }

@media (max-width: 1279px) {
  body.menu-expanded .CardColumnForm .Row {
    flex-direction: column;
    margin-bottom: 0 !important; }
    body.menu-expanded .CardColumnForm .Row .Column {
      margin-right: 0 !important;
      margin-bottom: 12px; } }

@media (max-width: 1119px) {
  body.menu-collapsed .CardColumnForm .Row {
    flex-direction: column;
    margin-bottom: 0 !important; }
    body.menu-collapsed .CardColumnForm .Row .Column {
      margin-right: 0 !important;
      margin-bottom: 12px; } }
