@import "../../styles/consts.scss";

.Documents {
  &__document {
    padding: 0 36px;
    border-bottom: $box-border-dotted;
    height: 80px;
    transition: height 1s;
    overflow: hidden;
    cursor: pointer;
    &--open {
      height: auto;
    }
    &__status {
      margin-bottom: 12px;
      font-family: "Poppins";
      font-size: 1.3rem;
      font-weight: 500;
      color: $color-gray-6;
      &--expired {
        color: $color-yellow;
      }
      &--refused {
        color: $color-red;
      }
      &--valid {
        color: $color-green;
      }
      &--pending {
        color: $color-blue;
      }
    }
    &__header {
      padding: 24px 0;
      &__name {
        font-family: "Poppins";
        font-size: 1.3rem;
        font-weight: 500;
        color: $color-gray-6;
        img {
          margin-right: 12px;
        }
      }
      &__status {
        margin-right: 16px;
        font-size: 1.1rem;
        font-weight: 600;
        color: $color-gray-9;
        &--expired {
          color: $color-yellow;
        }
        &--refused {
          color: $color-red;
        }
        &--valid {
          color: $color-green;
        }
        &--pending {
          color: $color-blue;
        }
      }
    }
    &__list {
      &__item {
        width: 142px;
        margin-right: 12px;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &__image {
          width: 142px;
          height: 88px;
          cursor: pointer;
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
          }
        }
        &__pdf {
          width: 142px;
          height: 88px;
          border: solid 1px $color-gray-eb;
          background-color: $color-gray-fa;
        }
        &__name {
          font-size: 1.1rem;
          line-height: 1.45;
          margin-top: 6px;
          margin-bottom: 20px;
        }
      }
    }
  }
  &__preview {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: $preview-background;
    &__container {
      background: white;
      padding: 12px 24px 24px 24px;
      position: relative;
    }
    &__close {
      position: absolute;
      right: 12px;
      top: 12px;
      cursor: pointer;
    }
    &__name {
      font-family: "Poppins";
      font-size: 1.6rem;
      color: $color-gray-6;
      font-weight: 600;
      line-height: 1.5;
    }
    &__image {
      img {
        width: auto;
        height: auto;
        max-width: 560px;
        max-height: 636px;
        min-width: 300px;
        min-height: 300px;
      }
    }
  }
}
