@import "../../styles/consts.scss";

.SidePanel {
  &__panel {
    height: 100vh;
    position: absolute;
    transition: right 0.3s;
    background: white;
    right: -50vw;
    z-index: 1099;
    &.visible {
      right: 0;
    }
  }
  &__background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    background: $side-panel-background;
  }
}
