@import "../../styles/consts.scss";

.Tickets {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  &__participating {
    background: $color-gray-f2;
    padding: 0 6px;
    img {
      width: 14px;
      height: 14px;
      margin-left: 2px;
    }
  }
  &__header {
    margin-bottom: 32px;
    &__title {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 2rem;
      color: $color-gray-6;
      margin-right: 40px;
    }
    &__filters {
      &__status {
        position: relative;
        z-index: 9;
        margin-right: 16px;
      }
      .Button {
        font-weight: normal;
        font-family: "Open Sans";
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        padding: 0 12px;
        img {
          margin-right: 10px;
          &:nth-child(2) {
            margin-right: 0;
            margin-left: 16px;
            margin-top: 2px;
          }
        }
      }
      &__dropdown {
        width: 384px;
        z-index: 1001;
        position: absolute;
        left: 0;
        top: 38px;
        border-radius: 3px;
        box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.07), 1px 1px 6px 0 rgba(0, 0, 0, 0.12);
        background: white;
        padding: 24px 12px 12px 24px;
        .Chip {
          margin-right: 12px;
          margin-bottom: 12px;
        }
        &__section {
          padding-bottom: 4px;
          margin-bottom: 16px;
          border-bottom: $box-border;
          &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
          }
          &__title {
            color: $color-gray-6;
            font-family: "Poppins";
            font-size: 1.1rem;
            font-weight: 500;
            line-height: 1.27;
            margin-bottom: 12px;
          }
        }
      }
      &__date {
        position: relative;
        z-index: 9;
        &__calendar {
          z-index: 2;
          position: absolute;
          padding: 24px;
          background: white;
          top: 38px;
          border-radius: 3px;
          box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.07), 1px 1px 6px 0 rgba(0, 0, 0, 0.12);
          background: white;
        }
      }
    }
    &__actions {
      .SearchInput {
        margin-right: 0;
      }
    }
  }
  &__list {
    padding: 0;
    width: 100%;
    display: flex;
    flex-grow: 1;
    border-radius: 3px;
    border: $box-border;
    background-color: white;
    overflow: hidden;
  }
  .react-datepicker__day {
    font-weight: 600;
    font-family: "Open Sans";
    font-size: 1.2rem;
    width: 32px;
    height: 32px;
    line-height: 32px;
    color: $color-gray-4d;
  }
  .react-datepicker__day--outside-month {
    color: $color-gray-c7;
  }
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: $color-gray-f2;
    color: $color-gray-4d;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background-color: $color-primary;
    color: white;
  }
  .react-datepicker__day-names {
    margin-top: 20px;
  }
  .react-datepicker__day-name {
    width: 32px;
    font-family: "Open Sans";
    color: $color-gray-6;
    font-size: 1.1rem;
  }
  .react-datepicker__header {
    border: none;
    background: white;
  }
  .react-datepicker__month {
    margin-top: 12px;
  }
  .react-datepicker {
    border: none;
  }
  .react-datepicker__current-month {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1.5rem;
    color: $color-gray-6;
  }
  .MuiTableBody-root {
    .MuiTableRow-root.MuiTableRow-hover:hover {
      cursor: pointer;
      .MuiTableCell-root {
        background-color: $color-gray-f5;
      }
    }
  }
}

#SupportCenter .PageHeader {
  .Tickets__header__filters__dropdown {
    flex-wrap: wrap;
    left: 40px;
    width: 384px !important;
  }
  &__filters .Button {
    img.showOnCompressed {
      display: none;
    }
  }
}

#SupportCenter {
  .avatar {
    position: relative;
    margin-left: 24px !important;
    &__initial {
      width: 26px;
      height: 26px;
      overflow: hidden;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.2rem;
      color: white;
      &.new {
        background: $color-primary;
      }
      &.open {
        background: $color-green2;
      }
      &.closed {
        background: $color-red4;
      }
    }
    &__tip {
      display: none;
      position: absolute;
      top: 28px;
      left: 45%;
      transform: translate(-45%, 0);
      z-index: 1000;
      border-radius: 3px;
      padding: 3px 8px 5px;
      box-shadow: 1px 1px 5px 0 #0000001e, 0 0 3px 0 #0000000f;
      background: $color-gray-3;
      font-weight: 600;
      font-size: 1.1rem;
      font-family: "Open Sans";
      white-space: nowrap;
      color: $color-white;
    }
    &:hover {
      .avatar__tip {
        display: inline-block !important;
        overflow: visible !important;
      }
    }
  }
  .Tickets__header__actions .SearchInput {
    margin-right: 0 !important;
  }
  .MuiTableBody-root {
    .MuiTableRow-root.MuiTableRow-hover:hover {
      cursor: pointer;
      .MuiTableCell-root {
        background-color: $color-gray-f5;
      }
    }
  }
  .MuiTableCell-root.MuiTableCell-body:nth-child(1) {
    overflow-x: visible !important;
    overflow-y: visible !important;
    padding-left: 0 !important;
  }
  .MuiTableCell-root.MuiTableCell-head:nth-child(3),
  .MuiTableCell-root.MuiTableCell-body:nth-child(3),
  .MuiTableCell-root.MuiTableCell-head:nth-child(4),
  .MuiTableCell-root.MuiTableCell-body:nth-child(4) {
    flex-grow: 0 !important;
    max-width: 60px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

body.menu-expanded {
  @media (max-width: 1279px) {
    #SupportCenter {
      .PageHeader {
        &__filters .Button {
          img {
            margin-right: 0;
            &.showOnExpanded.active {
              display: none;
            }
            &.showOnCompressed.active {
              display: block;
              margin-left: 0;
            }
          }
          .textToHide {
            display: none;
          }
        }
      }
      .PageHeader {
        &__actions__lastButton .Button {
          padding: 0;
          .Button__prefixIcon {
            margin-left: 7px;
          }
          .request {
            display: none;
          }
        }
        .dateFilter img {
          &.cleanDateFilter {
            margin-left: 8px;
            margin-right: -8px !important;
          }
        }
      }
      .ListBody {
        .MuiTableCell-stickyHeader,
        .MuiTableCell-root.MuiTableCell-body {
          &:nth-child(7) {
            padding-right: 24px;
          }
        }
      }
    }
  }
}

body.menu-collapsed {
  @media (max-width: 1119px) {
    #SupportCenter {
      .PageHeader {
        &__filters .Button {
          img {
            margin-right: 0;
            &.showOnExpanded.active {
              display: none;
            }
            &.showOnCompressed.active {
              display: block;
              margin-left: 0;
            }
          }
          .textToHide {
            display: none;
          }
        }
      }
      .PageHeader {
        &__actions__lastButton .Button {
          padding: 0;
          .Button__prefixIcon {
            margin-left: 7px;
          }
          .request {
            display: none;
          }
        }
        .dateFilter img {
          &.cleanDateFilter {
            margin-left: 8px;
            margin-right: -8px !important;
          }
        }
      }
      .ListBody {
        .MuiTableCell-stickyHeader,
        .MuiTableCell-root.MuiTableCell-body {
          &:nth-child(7) {
            padding-right: 24px;
          }
        }
      }
    }
  }
}
