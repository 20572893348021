@import "../../styles/consts.scss";

.Calendar {
  width: calc(100vw - 320px);
  height: 100%;
  &__districts {
    width: 240px;
    margin-right: 30px;
    background: white;
    border: $box-border;
    overflow-y: scroll;
    height: 100%;
    &__item {
      font-family: "Poppins";
      font-weight: 500;
      display: flex;
      align-items: center;
      padding: 0 28px;
      height: 40px;
      border-bottom: $box-border-dotted;
      cursor: pointer;
      &:hover {
        background: $color-gray-f7;
      }
      &.active {
        background: $color-gray-f7;
        border-right: 3px solid $color-primary;
      }
    }
  }
  &__wrapper {
    flex: 1;
  }
  .calendar {
    height: 100%;
    padding: 0;
    .months-container {
      .month-container {
        max-width: 25%;
        padding: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
