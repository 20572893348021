@import "../../styles/consts.scss";

.Shipments {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  &__header {
    margin-bottom: 32px;
    &__title {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 2rem;
      color: $color-gray-6;
      margin-right: 40px;
    }
    &__filters {
      &__status {
        position: relative;
        z-index: 9;
        margin-right: 16px;
      }
      .Button {
        font-weight: normal;
        font-family: "Open Sans";
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        padding: 0 12px;
        img {
          margin-right: 10px;
          &:nth-child(2) {
            margin-right: 0;
            margin-left: 16px;
            margin-top: 2px;
          }
        }
      }
      &__dropdown {
        width: 348px;
        z-index: 1001;
        position: absolute;
        left: 0;
        top: 38px;
        border-radius: 3px;
        box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.07), 1px 1px 6px 0 rgba(0, 0, 0, 0.12);
        background: white;
        padding: 24px 12px 12px 24px;
        .Chip {
          margin-right: 12px;
          margin-bottom: 12px;
        }
      }
      &__date {
        position: relative;
        z-index: 9;
        &__calendar {
          z-index: 2;
          position: absolute;
          padding: 24px;
          background: white;
          top: 38px;
          border-radius: 3px;
          box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.07), 1px 1px 6px 0 rgba(0, 0, 0, 0.12);
          background: white;
        }
      }
    }
    &__actions {
      .SearchInput {
        margin-right: 0;
      }
    }
  }
  &__list {
    padding: 0;
    width: 100%;
    display: flex;
    flex-grow: 1;
    border-radius: 3px;
    border: $box-border;
    background-color: white;
    overflow: hidden;
    &.display-none {
      display: none;
    }
    .collecting {
      color: #fa3d3d;
    }
    .pending {
      color: #45a225;
    }
    .billed {
      color: #dcac21;
    }
    .collected {
      color: #3584da;
    }
    .payed {
      color: $color-primary;
    }
    .MuiTableCell-root {
      border: none;
    }
    .MuiTableCell-head,
    .MuiTableCell-body {
      /* dates */
      &:nth-child(2),
      &:nth-child(4) {
        flex-grow: 1;
        overflow: visible !important;
        max-width: 150px;
        padding: 2px 0;
        margin: 0;
        text-align: left;
        vertical-align: middle;
      }
      /* status */
      &:nth-child(3) {
        flex-grow: 1;
        overflow: visible !important;
        max-width: 150px;
        padding: 2px 0;
        margin: 0;
        text-align: left;
        vertical-align: middle;
      }
      /* shipment & invoice */
      &:nth-child(5),
      &:nth-child(8) {
        flex-grow: 0;
        overflow: visible !important;
        width: 45px;
        max-width: 45px;
        padding: 2px 0;
        margin: 0;
        text-align: left;
        vertical-align: middle;
      }
      &:nth-child(8) {
        width: 125px;
        max-width: 125px;
        padding-left: 80px !important;
      }
      &:nth-child(6),
      &:nth-child(9) {
        flex-grow: 0;
        overflow: visible !important;
        width: 15px;
        max-width: 15px;
        padding: 2px 0;
        margin: 0;
        text-align: center;
        vertical-align: middle;
        hr {
          color: $color-gray-e0;
        }
      }
      &:nth-child(7),
      &:nth-child(10) {
        flex-grow: 0;
        overflow: visible !important;
        min-width: 145px !important;
        width: 145px !important;
        max-width: 145px !important;
        padding: 2px 0;
        padding-right: 20px !important;
        margin: 0;
        text-align: left;
        vertical-align: middle;
      }
      /*
      &:nth-child(7) {
        width: 125px;
        max-width: 125px;
        padding-right: 0 !important;
      }
      */
    }
    .base-amount {
      float: right !important;
      text-align: right;
    }
    thead.MuiTableHead-root .MuiTableCell-head {
      overflow: visible !important;
      &:nth-child(5),
      &:nth-child(8) {
        z-index: 1000 !important;
        overflow: visible;
      }
    }
  }
  .MuiTableRow-root {
    cursor: pointer;
  }
  .MuiTableCell-root.MuiTableCell-head {
    cursor: default;
    &.MuiTableSortLabel-root {
      cursor: pointer;
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root.MuiTableRow-hover:hover {
      cursor: pointer;
      .MuiTableCell-root {
        background-color: $color-gray-f5;
      }
    }
  }
}
