@import "../../../styles/consts.scss";

.ChangeAvatar {
  padding: 28px 36px;
  border-top: $box-border;
  &__title {
    margin-bottom: 34px;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 1.3rem;
    color: $color-gray-6;
  }
  &__content {
    padding: 0 42px;
  }
  &__wrapper-crop {
    width: 360px;
    height: 360px;
    margin-bottom: 20px;
    position: relative;
  }
  .reactEasyCrop_Container {
    max-height: 360px;
    max-width: 100%;
    width: auto;
    height: auto;
  }
  .reactEasyCrop_CropArea {
    color: rgba(255, 255, 255, 0.7);
    border: 1px solid $color-primary;
  }
  button {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
}
