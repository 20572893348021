@import "../../styles/consts.scss";

#Tickets {
  .avatar {
    position: relative;
    margin-left: 24px !important;
    &__initial {
      width: 26px;
      height: 26px;
      overflow: hidden;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.2rem;
      color: white;
      &.new {
        background: $color-primary;
      }
      &.open {
        background: $color-green2;
      }
      &.closed {
        background: $color-red4;
      }
    }
    &__tip {
      display: none;
      position: absolute;
      top: 28px;
      left: 45%;
      transform: translate(-45%, 0);
      z-index: 1000;
      border-radius: 3px;
      padding: 3px 8px 5px;
      box-shadow: 1px 1px 5px 0 #0000001e, 0 0 3px 0 #0000000f;
      background: $color-gray-3;
      font-weight: 600;
      font-size: 1.1rem;
      font-family: "Open Sans";
      white-space: nowrap;
      color: $color-white;
    }
    &:hover {
      .avatar__tip {
        display: inline-block !important;
        overflow: visible !important;
      }
    }
  }
  .PageHeader__filters__dropdown {
    width: 281px !important;
  }
  .PageBody
    .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:first-child
    .MuiButtonBase-root
    .MuiTableSortLabel-icon {
    margin-left: -2px !important;
  }
  .MuiTableBody-root {
    .MuiTableRow-root.MuiTableRow-hover:hover {
      cursor: pointer;
      .MuiTableCell-root {
        background-color: $color-gray-f5;
      }
    }
  }
  .MuiTableCell-root.MuiTableCell-body:nth-child(1) {
    overflow-x: visible !important;
    overflow-y: visible !important;
    padding-left: 0 !important;
  }
}

body.menu-expanded {
  @media (max-width: 1279px) {
    #Tickets .SearchInput {
      width: 283px !important;
    }
    #Tickets span.newTicket {
      display: none;
    }
  }
}

body.menu-collapsed {
  @media (max-width: 1119px) {
    #Tickets .SearchInput {
      width: 283px !important;
    }
    #Tickets span.newTicket {
      display: none;
    }
  }
}
