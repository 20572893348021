@import "../../../styles/consts.scss";

#TicketDetail {
  .FullPagePanel {
    flex-wrap: nowrap;
    position: absolute;
    left: -40px;
    top: -40px;
    right: -40px;
    bottom: -40px;
    min-width: 100%;
    min-height: 100%;
    background: $color-gray-fa;
    z-index: 9;
    &__header {
      padding: 24px 40px;
      box-shadow: 4px 2px 10px 0 rgba(124, 124, 124, 0.04), 4px 4px 7px 0 rgba(0, 0, 0, 0.03);
      &__title {
        color: $color-gray-6;
        font-weight: 600;
        font-size: 2rem;
        font-family: "Poppins";
        img {
          margin-right: 12px;
        }
      }
    }
    &__body {
      padding: 4px 24px;
      flex: 1;
      width: 100%;
      max-width: 1120px;
      align-self: center;
    }
    &__section {
      padding: 20px 16px;
      border-bottom: $box-border-dotted;
      &__title {
        font-family: "Poppins";
        font-size: 1.3rem;
        font-weight: 500;
        color: $color-gray-6;
        margin-bottom: 8px;
      }
      &__row {
        margin-bottom: 12px;
        display: flex;
        flex: 1 1 0px;
        justify-content: space-between;
        &__column {
          display: flex;
          flex-basis: 100%;
          margin-right: 12px;
          &:last-child {
            margin-right: 0;
          }
          & > div {
            flex-basis: 100%;
            margin-right: 12px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        @media (max-width: 1024px) {
          flex-wrap: wrap;
          &__column {
            margin: 0;
            &:first-child {
              margin-bottom: 16px;
            }
          }
        }
      }
    }

    .MuiFormControl-root .MuiOutlinedInput-input {
      padding: 0 14px;
      height: 32px;
      line-height: 32px;
    }
    .MuiSelect-iconOutlined {
      right: 0;
      top: 9px;
    }
    .MuiInputLabel-outlined {
      z-index: 1;
      transform: translate(14px, 11px) scale(1);
    }
  }
}

#TicketDetail {
  .TicketDetail {
    background: $color-gray-fa !important;
    &__header {
      background: white;
    }
    &__wrapper {
      padding-top: 16px;
    }
    &__info {
      padding: 16px 24px 20px;
      &__title {
        margin-bottom: 4px;
      }
      &__item {
        margin-top: 16px;
        font-size: 1.1rem;
        .Button {
          height: 24px;
        }
        &__label {
          font-weight: 600;
          width: 76px;
        }
        &__value {
          flex: 1;
        }
      }
    }
    &__box {
      background: white;
      border: $box-border;
      border-radius: 3px;
      padding-top: 16px;
      margin-bottom: 16px;
      &__action {
        margin-top: 12px;
      }
      &__data {
        flex: 1;
        flex-wrap: nowrap;
        position: relative;
        > div:first-child {
          height: 40px;
          margin: 0 0 16px !important;
        }
        .avatarUserCenter {
          flex-grow: 1;
          margin-left: 0 !important;
          width: auto;
        }
        > div.ck-editor {
          width: 100%;
        }
      }
      &__avatar {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        margin-top: 0;
        margin-right: 20px;
        background: $color-gray-f2;
        text-transform: uppercase;
        font-family: "Poppins";
        font-weight: 600;
        img {
          width: 100%;
          height: auto;
        }
      }
      &__date {
        color: $color-gray-9;
        font-weight: 600;
        font-size: 1.3rem;
      }
      &__title {
        height: 40px;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 16px;
        &__company {
          position: relative;
          color: $color-gray-6;
          font-size: 1.3rem;
          font-weight: normal;

          margin-left: 0;
          font-family: "Open Sans";
        }
      }
      &__attachments {
        &__item {
          cursor: pointer;
          font-weight: 600;
          &__wrapper-image {
            margin-right: 8px;
            width: 24px;
          }
          &__delete {
            img {
              width: 16px;
              margin-left: 4px;
            }
          }
          margin-right: 24px;
          color: $color-gray-6;
          font-size: 1.3rem;
          margin-top: 16px;
          &__wrapper-image {
            img {
              max-width: 24px;
              max-height: 24px;
              width: 100%;
              height: auto;
            }
          }
        }
      }
      &__text {
        font-size: 1.3rem;
        line-height: 1.54;
      }
      &__metadata {
        font-size: 1.1rem;
        line-height: 20px;
        padding-left: 16px;
        margin-top: 24px;
      }
    }
    &__attach {
      .Button {
        height: 24px;
      }
      img {
        margin-right: 6px;
        width: 12px;
        height: 12px;
      }
    }
    &__col-left {
      width: 260px;
      margin-right: 16px;
      .TicketDetail__box__title {
        height: 24px;
        margin-bottom: 20px;
        line-height: 24px;
      }
    }
    &__col-right {
      flex: 1;
      .TicketDetail__box {
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .ck-editor {
          max-width: 100% !important;
        }
      }
    }
    &__status {
      flex-grow: 0 !important;
      width: 20px;
      height: 20px;
      overflow: hidden;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.8rem;
      color: white;
      margin-right: 16px;
      &.new {
        background: $color-primary;
      }
      &.open {
        background: $color-green2;
      }
      &.closed {
        background: $color-red4;
      }
    }
    &__preview {
      position: fixed;
      z-index: 1000 !important;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      background: $preview-background;
      &__container {
        background: white;
        padding: 24px;
        position: relative;
      }
      &__close {
        position: absolute;
        right: 6px;
        top: 6px;
        cursor: pointer;
      }
      &__image {
        img {
          width: auto;
          height: auto;
          max-width: 560px;
          max-height: 636px;
          min-width: 300px;
          min-height: 300px;
        }
      }
    }
    .ck-editor__editable {
      min-height: 100px;
    }
    ul,
    ol {
      li {
        display: list-item;
        margin: initial;
        padding: initial;
      }
    }

  }
}

#TicketDetail.PageWrapper {
  .PageHeader {
    &__goBack {
      flex-grow: 0 !important;
    }
    &__title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      flex-grow: 1;
      .TicketDetail {
        position: relative;
        flex-wrap: nowrap;
        &__status {
          flex-grow: 0;
          position: relative;
          top: -7px;
          width: 40px;
          height: 40px;
        }
        &__title {
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis !important;
        }
      }
    }
  }
}

body.menu-expanded {
  #TicketDetail.PageWrapper {
    .PageHeader {
      &__title .TicketDetail__title {
        width: calc(100vw - 485px) !important;
        &.SupportCenter {
          width: calc(100vw - 525px) !important;
        }
      }
    }
  }
  @media (max-width: 1279px) {
    #TicketDetail.PageWrapper .TicketDetail {
      &__col-left {
        width: 230px !important;
      }
      &__col-right {
        .TicketDetail__box {
          .ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items > div:first-child {
            max-width: 120px !important;
          }
        }
      }
      &__preview {
        &__container {
          left: 32px;
        }
      }
    }
  }
}

body.menu-collapsed {
  #TicketDetail.PageWrapper {
    .PageHeader {
      &__title .TicketDetail__title {
        width: calc(100vw - 325px) !important;
        &.SupportCenter {
          width: calc(100vw - 365px) !important;
        }
      }
    }
  }
  @media (max-width: 1119px) {
    #TicketDetail.PageWrapper .TicketDetail {
      &__col-left {
        width: 230px !important;
      }
      &__col-right {
        .TicketDetail__box {
          .ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items > div:first-child {
            max-width: 120px !important;
          }
        }
      }
      &__preview {
        &__container {
          left: 32px;
        }
      }
    }
  }
}
