@import "../../styles/consts.scss";

.Layout {
  flex-wrap: nowrap!important;
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  &__main-content {
    flex-grow: 1;
    max-height: 100vh;
    height: 100vh;
    padding: 40px;
  }
}
