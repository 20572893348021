@import "../../../styles/consts.scss";

#ProvidersMapping {

  .PageBody > .Form > .CardRowWrapper {
    padding: 0;
    > .Card {
      min-height: 118px!important;
      height: 118px!important;
      max-height: 118px!important;
      padding: 16px 24px 24px;
      > div {
        min-width: 100% !important;
      }
    }
    .CardForm {
      height: 112px !important;
      padding: 16px 24px 24px!important;
      > div {
        min-width: 100% !important;
      }
    }
    .CardFormTitle {
      margin-bottom: 16px !important;
      @include font-poppins(600, 14px, normal);
      color: $color-gray-6;
    }
    .CardFormContent {
      margin-right: 0;
      margin-left: 0;
      > div {
        margin-right: 16px;
      }
    }
  }

  #ProvidersMappingAutocomplete {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    //width: 100% !important;
    //min-width: 100% !important;
    width: 420px!important;

    height: 32px !important;
    max-height: 32px !important;
    padding: 0 40px 0 13px;
  }

  .MuiAutocomplete-option {
    font-family: $open-sans;
    font-size: 11px;
    font-weight: normal;
    color: $color-gray-4d;
  }
  
  .MuiAutocomplete-popupIndicator,
  .MuiAutocomplete-popupIndicatorOpen {
    position: absolute;
    top: 4px;
    right: 0;
    .MuiSvgIcon-root {
      transform: scale(1.7) !important;
    }
  }
  
  .MuiAutocomplete-root .MuiAutocomplete-clearIndicatorDirty {
    position: absolute;
    top: 4px;
    right: 16px;
  }

}

#ProvidersMapping {
  .PageBody > div.CardRowWrapper {
    .Card {
      position: relative;
      overflow-x: hidden;
      overflow-y: scroll;
      max-height: calc(100vh - 270px)!important;
      padding-bottom: 24px !important;
      .AZ {
        width: 100% !important;
      }
      .AZ-group {
        .AZ-header {
          min-width: 100%;
          margin-bottom: 10px;
          border-bottom: 1px solid $color-gray-eb;
          padding: 16px 24px 12px;
          @include font-poppins(500, 13px, normal);
          color: $color-gray-6;
        }
        .AZ-content {
          margin: 0;
          padding: 0 24px;
          margin-bottom: 12px;
          .AZ-provider {
            flex-wrap: wrap;
            min-width: 25% !important;
            width: 25% !important;
            max-width: 25% !important;
            max-height: 20px!important;
            margin-right: 0 !important;
            margin-left: -3px !important;
            padding-top: 0;
            .customerCodeData {
              margin-right: 5px;
              padding: 0 5px;
              background: $color-gray-f2;
            }
            > img {
              border: none;
              flex-grow: 0;
              width: 16px;
              margin-right: 6px;
              cursor: pointer;
            }
            > div {
              flex: 1 !important;
              overflow: hidden;
              padding-right: 24px;
              @include font-open-sans(normal, 12px, 20px);
              text-overflow: ellipsis;
              white-space: nowrap;
              color: $color-gray-4d;
            }
          }
        }
      }

      .Autocomplete {
        min-width: 400px !important;
        margin-right: 16px;
      }
    }
  }
}

.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  height: 32px;
}

.MuiAutocomplete-popper {
  overflow-y: auto;
  max-height: 295px;
  box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.07), 1px 1px 6px 0 rgba(0, 0, 0, 0.12);
}
.MuiAutocomplete-listbox {
  @include font-open-sans(normal, 12px, normal);
  color: $color-gray-4d;
}


#ProvidersMapping {
  .ModalWindow {
    &__title {
      margin-bottom: 44px !important;
    }
    &__text {
      margin-bottom: 52px !important;
    }
  }
}