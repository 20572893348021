@import "../../../styles/consts.scss";

.PasswordRecovery {
  height: 100vh;
  width: 100vw;
  background: $color-gray-fa;
  &__wrapper {
    width: 536px;
    padding: 80px;
    border-radius: 3px;
    border: $box-border;
    background: white;
  }
  &__logo {
    margin-bottom: 40px;
  }
  &__title {
    font-size: 2rem;
    font-family: "Poppins";
    font-weight: 600;
    color: $color-gray-4d;
    margin-bottom: 16px;
  }
  &__text {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 32px;
  }
  .Input {
    margin-bottom: 64px;
  }
  .Button {
    height: 48px;
    width: 100%;
    margin-bottom: 16px;
    font-size: 1.6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.PasswordRecovery {
  .MuiFormControl-root {
    width: 100%;
    .MuiOutlinedInput-input {
      height: 48px !important;
      padding: 0 16px !important;
      font-family: "Open Sans" !important;
      font-size: 16px !important;
      line-height: 16px !important;
      color: $color-gray-4d !important;
    }
    .MuiFormLabel-root {
      font-family: "Open Sans" !important;
      font-size: 16px !important;
      line-height: 16px !important;
      color: $color-gray-9 !important;
    }
    .MuiFormLabel-root.Mui-focused {
      color: $color-primary !important;
    }
    .MuiInputLabel-outlined {
      z-index: 1 !important;
      transform: translate(20px, 15px) scale(1) !important;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      font-family: "Open Sans" !important;
      padding-bottom: 10px !important;
      padding-right: 4px !important;
      transform: translate(14px, -6px) scale(0.75) !important;
      background: $color-white !important;
    }
  }
}
