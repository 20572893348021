@import "../../styles/consts.scss";

/* Menu */
.MenuWrapper {
  position: relative;
  z-index: 999 !important;
  &.SendedBack {
    z-index: unset !important;
  }
}

.Menu {
  z-index: 10000 !important;
  overflow-x: hidden;
  width: 240px;
  transition: width 0.4s ease;
  height: 100vh;
  background: $color-gray-14;
  @include font-poppins(500, 12px, normal);
  color: $color-gray-6;
  .react-tooltip {
    display: none !important;
    z-index: 100000 !important;
    height: 24px !important;
    max-height: 24px !important;
    margin-left: 19px !important;
    border-radius: 3px !important;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.06) !important;
    padding: 0 8px !important;
    background: $color-gray-3 !important;
    font-size: 11px !important;
    font-weight: 600 !important;
    font-family: "Open Sans", sans-serif !important;
    line-height: 20px !important;
    color: $color-gray-f2 !important;
  }
  &__nav__footer {
    .react-tooltip {
      margin-left: 35px !important;
    }
  }
}

/* Header */
.Menu {
  &__header {
    height: 72px;
    padding: 24px 16px 24px 28px;
    .wrapper {
      display: flex;
      opacity: 1;
      width: 100%;
      height: 24px;
      margin-right: 16px;
      padding: 0;
      transition: opacity 0.4s ease, width 0.4s ease-out;
      transition: opacity 0.4s ease, transform 0.4s ease-out;
    }
    &__logo {
      display: block;
      width: 111px;
      transition: width 0.4s ease;
      height: 24px;
      margin-right: 8px;
    }
    &__title {
      flex-grow: 0;
      flex-shrink: 0;
      display: block;
      margin-top: -5px;
      .environment {
        @include font-poppins(500, 9px, 1.78);
        font-size: 9px !important;
        letter-spacing: -0.6px;
        color: $color-primary;
      }
      .environment-dev {
        @include font-poppins(500, 9px, 1.78);
        font-size: 9px !important;
        letter-spacing: -0.6px;
        color: $color-info;
      }
      .languageWrapper {
        display: none;
        position: relative;
        @include font-poppins(500, 10px, 1.6);
        font-size: 10px !important;
        color: $color-white;
        .language {
          cursor: pointer;
          &__select {
            cursor: pointer;
            position: absolute;
            &__item {
              padding-left: 0!important;
            }
          }
        }
      }
    }
    &__arrow {
      position: absolute;
      top: 28px;
      right: 16px;
      cursor: pointer;
      > img {
        width: 16px !important;
        height: 16px !important;
      }
      .default {
        display: block;
      }
      .active {
        display: none;
      }
      &:hover {
        .default {
          display: none;
        }
        .active {
          display: block;
        }
      }
    }
  }
}

/* Group */
.Menu {
  .OptionGroup {
    cursor: pointer;
    .GroupHeader {
      position: relative;
      img.dropdown {
        position: absolute;
        top: 16px;
        left: 10px;
        width: 14px;
        height: 14px;
        &.default {
          display: block;
        }
        &.active {
          display: none;
        }
      }
      &:hover {
        img.dropdown.default {
          display: none;
        }
        img.dropdown.active {
          display: block;
        }
      }
      &.Menu__nav__item--active {
        img.dropdown.default {
          display: none !important;
        }
        img.dropdown.active {
          display: block !important;
        }
      }
    }
  }
  .OptionsWrapper {
    position: relative;
    .verticalLine {
      position: absolute;
      top: 4px;
      left: 15px;
      width: 2px;
      height: calc(100% - 16px);
      background: $color-gray-6;
    }
  }
}

/* Nav */
.Menu {
  &__nav {
    flex: 1;
    &__item {
      flex-wrap: nowrap!important;
      box-sizing: border-box;
      padding: 12px 28px;
      &__title {
        opacity: 1;
        transition: opacity 0.4s ease;
        white-space: nowrap;
      }
      &__icon {
        margin-right: 16px;
        &--default {
          display: block;
        }
        &--active {
          display: none;
        }
      }
      &--active,
      &:hover {
        .Menu__nav__item__icon {
          &--default {
            display: none;
          }
          &--active {
            display: block;
          }
        }
      }
      &:hover {
        background: $color-gray-26;
        color: white;
      }
      &--active {
        background-color: $color-orange-dark2;
        color: white;
      }
    }
    &__footer {
      width: 240px;
      border-top: 1px solid $color-gray-3;
      padding: 12px 28px 12px 28px;
      &__item {
        .box-icon {
          position: relative;
        }
        &__icon {
          margin-right: 0;
          &--default {
            display: block;
          }
          &--active {
            display: none;
          }
        }
        &__tip {
          display: none;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          z-index: $zindex-tooltip;
          margin-top: 5px;
          border-radius: 3px;
          padding: 3px 8px 5px;
          box-shadow: 1px 1px 5px 0 #0000001e, 0 0 3px 0 #0000000f;
          background: $color-gray-3;
          font-weight: 600;
          font-size: 1.1rem;
          font-family: "Open Sans";
          white-space: nowrap;
          color: $color-white;
        }
        &:hover {
          cursor: pointer;
          .Menu__nav__footer__item__icon {
            &--default {
              display: none;
            }
            &--active {
              display: block;
            }
          }
          .Menu__nav__footer__item__tip {
            display: block;
          }
          .box-icon__tip {
            display: block;
          }
        }
      }
    }
  }
}

/* Region Selector */
.Menu {
  &__regionSelector {
    max-width: 192px !important;
    margin: 12px 24px;
    .MuiFormControl-root {
      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        background: transparent !important;
      }
      .MuiOutlinedInput-input {
        color: $color-white !important;
      }
      .MuiSelect-outlined.MuiSelect-outlined {
        padding-right: 24px !important;
      }
    }
    .MuiOutlinedInput-root fieldset {
      border: 1px solid $color-gray-9 !important;
    }
    .MuiOutlinedInput-root.Mui-focused fieldset {
      border: 1px solid $color-primary !important;
    }
    .MuiSelect-icon {
      color: $color-gray-9 !important;
      &.MuiSelect-iconOpen {
        transform: scale(1.7, 1.7) rotate(180deg) !important;
      }
    }
  }
}

#menu-region {
  div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
    top: unset !important;
    bottom: 100px !important;
  }
}

/* Footer */
.Menu {
  &__footer {
    width: 100%;
    padding: 12px 28px;
    margin-top: auto;
    &__user {
      &__name {
        cursor: pointer;
        text-overflow: clip;
        white-space: nowrap;
        overflow: hidden;
        img {
          width: 24px;
          height: 24px;
          border-radius: 100%;
          overflow: hidden;
          margin-right: 16px;
        }
        .userName {
          margin-left: 0;
          opacity: 1;
          transition: opacity 0.4s ease;
          &:hover {
            color: $color-white;
          }
        }
      }
      &__logout {
        cursor: pointer;
        img {
          &:last-child {
            display: none;
          }
        }
        &:hover {
          img {
            &:first-child {
              display: none;
            }
            &:last-child {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

/* Menu collapsed */
.Collapsed {
  .Menu {
    width: 80px;
    .react-tooltip {
      display: block !important;
    }

    &__header {
      .wrapper {
        opacity: 0;
        width: 0;
      }
      .wrapper.nonExpandable {
        opacity: 1;
        width: 100%;
        .Menu__header__title {
          position: absolute;
          top: 12px;
          left: 28px;
          width: 25px;
          height: 57px;
          .environment,
          .environment-dev {
            margin-left: -3px;
          }
          &:last-child div:last-child {
            z-index: 1;
            width: 40px !important;
          }
        }
      }
      &__arrow {
        right: 30px;
      }
    }

    &__nav {
      &__item {
        &__title {
          opacity: 0;
        }
        .box-icon {
          position: relative;
        }
        &:hover {
          cursor: pointer;
          .Menu__nav__item__icon {
            &--default {
              display: none;
            }
            &--active {
              display: block;
            }
          }
        }
      }
      &__footer {
        flex-direction: column;
        width: 100%;
        min-width: 100%;
        height: 145px;
        padding: 12px 28px 12px 28px !important;
        align-content: space-between !important;
        &__item {
          margin: 0 !important;
        }
        &__item__tip {
          display: none !important;
        }
        &__item__icon {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
      }
    }

    .Notifications__tip {
      display: none !important;
    }

    &__regionSelector {
      max-width: calc(100% - 16px) !important;
      margin: 12px 8px;
      .MuiFormControl-root {
        .MuiSelect-outlined.MuiSelect-outlined {
          padding-right: 0px !important;
        }
      }
      .MuiFormControl-root .MuiOutlinedInput-input {
        padding: 0 0 0 14px !important;
      }
    }

    &__footer {
      &__user {
        &__name {
          text-overflow: clip;
          .userName {
            background: transparent;
            opacity: 0;
          }
        }
      }
    }
  }
}

.menu-collapsed {
  #menu-region {
    div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
      left: 8px !important;
    }
  }
}
