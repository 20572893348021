@import "../../../styles/consts.scss";

#AddCharts {
  .Add__body {
    margin: 0 !important;
    padding: 0 8px !important;
  }
  .Add__header__content {
    height: 20px !important;
  }
  .Add__header__content__description {
    line-height: 20px !important;
  }
  .chartWrapper {
    flex-wrap: nowrap;
    overflow-x: hidden;
    margin: 0 !important;
    border-bottom: 1px solid $color-gray-eb;
    padding-top: 24px;
    padding-bottom: 24px;
    .chartThumbnail {
      img {
        flex-grow: 0;
        width: 56px;
        height: 44px;
        margin-right: 16px;
        margin-left: 16px;
      }
    }
    .chartInfoBtn {
      flex-grow: 1;
      margin: 0;
      padding-right: 16px;
      .chartHeader {
        margin: 0;
        margin-bottom: 8px;
        .chartName {
          @include font-poppins(500, 13px, normal);
          color: $color-gray-6;
        }
        .Button {
          max-height: 24px;
          padding: 0 10px 0 8px;
          &__prefixIcon {
            width: 12px;
            height: 12px;
          }
        }
      }
      .chartDescription {
        @include font-open-sans(normal, 11px, normal);
        color: $color-gray-6;
      }
    }

  }
}
