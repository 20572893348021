@import '../../styles/consts.scss';

.Loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
  z-index: 9999999999999999;
  text-align: center;
  font-size: 2rem;
  img {
    width: 64px;
    height: 64px;
  }
}
