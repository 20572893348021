@import "../../../styles/consts.scss";

.FullPagePanel {
  flex-wrap: nowrap;
  position: absolute;
  left: -40px;
  top: -40px;
  right: -40px;
  bottom: -40px;
  min-width: 1100px;
  min-height: 100%;
  background: $color-gray-fa;
  z-index: 9;
  &__header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 80px;
    padding: 24px 40px;
    box-shadow: 4px 2px 10px 0 rgba(124, 124, 124, 0.04), 4px 4px 7px 0 rgba(0, 0, 0, 0.03);
    &__title {
      color: $color-gray-6;
      font-weight: 600;
      font-size: 2rem;
      font-family: "Poppins";
      img {
        margin-right: 12px;
      }
    }
  }
  &__body {
    margin: 96px auto 0;
    padding: 4px 24px;
    flex: 1;
    width: 100%;
    max-width: 1120px;
    align-self: center;
  }

  .MuiFormControl-root .MuiOutlinedInput-input {
    padding: 0 14px;
    height: 32px;
    line-height: 32px;
  }
  .MuiSelect-iconOutlined {
    right: 0;
    top: 9px;
  }
  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 11px) scale(1);
  }
}

.ShipmentDetail {
  background: $color-gray-fa !important;

  .shipment-status {
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    &.header {
      display: inline-block;
      margin: 0 32px 0px 12px !important;
    }
    &.collecting {
      color: #fa3d3d;
    }
    &.pending {
      color: #45a225;
    }
    &.billed {
      color: #dcac21;
    }
    &.collected {
      color: #3584da;
    }
    &.payed {
      color: $color-primary;
    }
  }
  .process-status {
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    color: $color-primary;
  }

  &__header {
    background: white;
  }
  &__wrapper {
    padding-top: 16px;
  }
  &__box {
    background: white;
    border: $box-border;
    border-radius: 3px;
    padding-top: 16px;
    margin-bottom: 16px;
    &__title {
      font-family: "Poppins";
      font-weight: 600;
      color: $color-gray-6;
      font-size: 14px;
    }
    &__body {
      font-size: 1.3rem;
      line-height: 1.54;
      height: 100%;
      &__info {
        flex: 1;
        overflow: hidden;
      }
      &__title {
        height: 20px !important;
        max-height: 20px !important;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 12px;
        line-height: 1.33;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex: 1;
      }
      &__text {
        margin-bottom: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &--amount {
          font-family: "Poppins";
          font-weight: 300;
          font-size: 2.8rem;
          line-height: 100%;
          span {
            display: inline-block;
            margin-left: 1px;
            color: $color-gray-9;
            font-size: 1.6rem;
            font-weight: 500;
          }
        }
      }
      &__icon {
        margin-left: 24px;
        margin-right: 48px;
      }
    }
  }
  &__col-unique {
    flex: 1;
    width: calc(100vw - 240px - 80px) !important;
    margin: 0 40px;
    .ShipmentDetail__box {
      padding: 16px 24px;
      min-height: 150px; //***height: 175px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      &--financial {
        height: auto;
      }
      &--half {
        width: calc((100vw - 240px - 80px - 16px) / 2) !important; //***
        min-height: 150px; //*** height: 190px;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
      }
      &__body {
        position: relative;
        max-width: calc(100vw - 240px) !important;
        &__financial {
          width: 100%;
          height: 100%;
          &__column {
            align-items: stretch;
            display: flex;
            flex-direction: column;
            margin-right: 75px;
            &:first-child,
            &:last-child {
              margin-right: 0;
            }
            &:last-child {
              width: 90px;
              max-width: 90px;
              margin-right: 10px;
            }
            &.process {
              height: 100%;
              max-height: 110px;
              overflow: auto;
              width: calc(((100vw - 240px - 80px - 32px) / 2) - 220px);
              min-width: calc(((100vw - 240px - 80px - 32px) / 2) - 220px);
              margin-top: -20px;
              .ShipmentDetail__box__body__text {
                width: calc(((100vw - 240px - 80px - 32px) / 2) - 230px);
                min-width: calc(((100vw - 240px - 80px - 32px) / 2) - 230px);
                height: 20px;
                min-height: 20px;
                margin-right: 16px;
                margin-bottom: 2px;
              }
            }
          }
          .ShipmentDetail__box__body__title {
            color: $color-gray-6;
            font-size: 1.3rem;
            font-weight: 500;
          }
        }
        &__data {
          flex: 1;
        }
      }
    }
  }

  .ShipmentDetail__col-unique .ShipmentDetail__box__body__financial__column {
    flex-grow: 1;
  }

  &__price {
    color: $color-gray-6;
    font-weight: 600;
    font-size: 2.4rem;
    font-family: "Poppins";
  }

  .download-file-wrapper {
    flex-grow: 0;
    width: 90px;
    min-width: 90px;
    margin-left: 16px;
    padding: 3px 0;
    cursor: pointer;
    img {
      margin-right: 8px;
    }
  }
}

#providers_invoices_list {
  flex: 0;
  box-sizing: content-box;
  width: calc(100vw - 240px - 80px) !important;
  max-width: calc(100vw - 240px - 80px) !important;
  min-width: calc(100vw - 240px - 80px) !important;
  margin-top: 0;
  padding: 0;
  .ShipmentDetail__box--financial {
    flex: 0;
    width: calc(100vw - 240px - 80px) !important;
    max-width: calc(100vw - 240px - 80px) !important;
    min-width: calc(100vw - 240px - 80px) !important;
    padding-bottom: 32px;
    padding-left: 8px;
  }
  .title {
    padding: 16px 16px 16px;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: $color-gray-6;
  }
  .MuiTableCell-stickyHeader {
    padding-bottom: 16px;
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10) {
      text-align: center;
    }
    &:nth-child(2) {
      padding-right: 0;
      padding-left: 0;
    }
    &:nth-child(4) {
      text-align: center;
    }
    &:nth-child(5) {
      max-width: 50px;
      padding-right: 0;
      padding-left: 0;
      text-align: center;
    }
  }
  .MuiTableRow-root {
    .MuiTableCell-root.MuiTableCell-body {
      height: 30px !important;
      padding: 0 16px;
      font-family: "Open Sans";
      font-size: 12px;
      line-height: 1.64;
      color: $color-gray-4d;
      &:nth-child(2) {
        min-width: 125px;
        padding-right: 0;
        padding-left: 0;
      }
      &:nth-child(4) {
        text-align: center;
      }
      &:nth-child(5) {
        max-width: 50px;
        padding-right: 0;
        padding-left: 0;
        text-align: center;
      }
      &:last-child {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  .pending {
    color: #fa3d3d;
  }
  .billed {
    color: #dcac21;
  }
  .collected {
    color: #3584da;
  }
  .payed {
    color: $color-primary;
  }
  .base-amount {
    float: right !important;
    text-align: right;
  }
}

.CollectInvoiceButtonWrapper {
  padding-left: 32px !important;
  & > Button {
    width: 116px;
    height: 32px;
    padding: 7px 0 6px;
    border-radius: 3px;
    border: solid 4px $color-gray-e0;
    background-color: $color-white;
  }
}

.os-scrollbar-vertical {
  margin-top: 85px;
  margin-bottom: 5px;
}
.os-scrollbar-handle {
  width: 4px;
}

@media (max-width: 1023px) {
  #Shipments {
    display: none;
  }
}

.Modal {
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background: $modal-background;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &__box {
    background: white;
    padding: 12px 24px 24px;
    border-radius: 3px;
    box-shadow: 0 8px 19px 0 rgba(0, 0, 0, 0.1), 10px 19px 46px 0 rgba(0, 0, 0, 0.07),
      20px 35px 38px 0 rgba(0, 0, 0, 0.07);
  }
}

.CollectInvoiceModal {
  width: 405px;
  &__title {
    font-family: "Poppins";
    font-size: 1.6rem;
    color: $color-gray-6;
    font-weight: 600;
    margin-bottom: 28px;
  }
  &__body {
    font-family: "Open Sans";
    font-size: 1.3rem;
    line-height: 20px;
    margin-bottom: 24px;
    padding: 0 16px;
    text-align: center;
    color: $color-gray-4d;
    &__Date {
      margin: 0 16px 40px;
    }
  }
  &__actions {
    margin-top: 40px;
    & > button:first-child {
      margin-right: 12px;
    }
  }
  .MuiSvgIcon-root:hover {
    cursor: pointer !important;
  }
}

.react-datepicker {
  border: 2px solid black;
}
.react-datepicker__day {
  font-weight: 600;
  font-family: "Open Sans";
  font-size: 1.2rem;
  width: 32px;
  height: 32px;
  line-height: 32px;
  color: $color-gray-4d;
}
.react-datepicker__day--outside-month {
  color: $color-gray-c7;
}
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: $color-gray-f2;
  color: $color-gray-4d;
}
.react-datepicker__day--selected,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: $color-primary;
  color: white;
}
.react-datepicker__day-names {
  margin-top: 20px;
}
.react-datepicker__day-name {
  width: 32px;
  font-family: "Open Sans";
  color: $color-gray-6;
  font-size: 1.1rem;
}
.react-datepicker__header {
  border: none;
  background: white;
}
.react-datepicker__month {
  margin-top: 12px;
}
.react-datepicker {
  border: none;
}
.react-datepicker__current-month {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.5rem;
  color: $color-gray-6;
}

.ShipmentDetail {
  &__date {
    position: relative;
    z-index: 9;
  }
}
