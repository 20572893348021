@import "../../styles/consts.scss";

#Profiles .ListBody {
  .cTable .MuiTableCell-root:nth-child(3) {
    flex-grow: 0;
    padding-left: 50px;
  }
  .cTable .MuiTableCell-root:nth-child(6) {
    flex-grow: 0;
  }
}
