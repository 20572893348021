@import "../../styles/consts.scss";

.title {
  text-align: center;
}

.form {
  border: solid black 1px;
}

.elem {
  display: inline-block;
  overflow: hidden;
  width: 150px;
  height: 20px;
}

.charts {
  text-align: center;
}

#BI .PageHeader {
  flex-wrap: nowrap!important;
  &__filters {
    margin-left: 40!important;
  }
  &__actions {
    position: absolute;
    right: 24px !important;
    min-width: 332px !important;
    width: 332px !important;
    max-width: 332px !important;
    .Button {
      &__prefixIcon {
        display: none;
      }
      &:nth-last-child(2) {
        margin-left: 16px;
      }
      &:last-child {
        margin-left: 16px;
      }
    }
  }
}

#BI .PageHeader__actions__lastButton {
  margin-left: 16px !important;
}

#BI .PageHeader .FullScreenActions {
  display: none!important;
}


#BI .FullPagePanel > .PageHeader > div:first-child {
  flex-wrap: nowrap!important;
  position: absolute;
  left: 24px !important;
  .PageHeader__title {
    overflow: hidden!important;
    white-space: nowrap!important;
    text-overflow: ellipsis!important;
  }
  .PageHeader__filters {
    min-width: 425px!important;
    .Button {
      white-space: nowrap!important;
    }
  }
}


#BI #PageBody__BI.PageBody.PageBody__BI {
  max-width: 1200px!important;
  min-height: 100%!important;
  margin-top: 80px!important;
  margin-right: 0!important;
  margin-left: 0!important;
  border-right: 1px solid $color-gray-e0;
  border-left: 1px solid $color-gray-e0;
  padding: 16px 11px!important;
}


#BI .os-host.os-host-foreign {
  position: relative;
  top: 0!important;
  height: 100%!important;
  min-height:calc(100vh)!important;
}

body.menu-expanded {
  #BI  #PageBody__BI.PageBody.PageBody__BI {
    width: calc(100vw - 240px - 22px)!important;
    max-width: 1200px!important;
    margin-right: 0!important;
    margin-left: 0!important;
  }
  @media (min-width: 1440px) {
    #BI #PageBody__BI.PageBody.PageBody__BI {
      width: 1200px!important;
      max-width: 1200px!important;
      margin-right: auto!important;
      margin-left: auto!important;
    }
  }
}

body.menu-collapsed {
  #BI  #PageBody__BI.PageBody.PageBody__BI {
    width: calc(100vw - 80px - 22px)!important;
    max-width: 1200px;
    margin-right: 0!important;
    margin-left: 0!important;
  }
  @media (min-width: 1280px) {
    #BI  #PageBody__BI.PageBody.PageBody__BI {
      width: 1200px!important;
      max-width: 1200px!important;
      margin-right: auto!important;
      margin-left: auto!important;
    }
  }
}

body.menu-expanded {
  @media (max-width: 1439px) {
    #BI  #PageBody__BI.PageBody.PageBody__BI {
      border-right: none;
      border-left: none;
    }
  }
}

body.menu-collapsed {
  @media (max-width: 1279px) {
    #BI  #PageBody__BI.PageBody.PageBody__BI {
      border-right: none;
      border-left: none;
    }
  }
}

body.menu-expanded {
  @media (max-width: 1279px) {
    #BI .PageHeader {
      &__actions {
        min-width: 132px !important;
        width: 132px !important;
        max-width: 132px !important;
        .Button {
          padding: 0 8px;
          &__prefixIcon {
            display: block;
            margin-right: 0;
          }
          &__label {
            display: none;
          }
          &:last-child {
            margin-left: 12px;
          }
        }
      }
    }
    #BI .FullPagePanel > .PageHeader > div:first-child {
      width: calc(100vw - 462px)!important; //414
      max-width: calc(100vw - 462px)!important; //414
    }
  }
}

body.menu-collapsed {
  @media (max-width: 1119px) {
    #BI .PageHeader {
      &__actions {
        min-width: 132px !important;
        width: 132px !important;
        max-width: 132px !important;
        .Button {
          padding: 0 8px;
          &__prefixIcon {
            display: block;
            margin-right: 0;
          }
          &__label {
            display: none;
          }
          &:last-child {
            margin-left: 12px;
          }
        }
      }
    }
    #BI .FullPagePanel > .PageHeader > div:first-child {
      width: calc(100vw - 254px)!important;
      max-width: calc(100vw - 254px)!important;
    }
  }
}
