@import "../../../styles/consts.scss";

.AddVehicleType {
  width: 522px;
  &__header {
    padding: 20px 24px 24px 24px;
    border-bottom: $box-border;
    &__title {
      font-family: "Poppins";
      font-size: 1.8rem;
      font-weight: 600;
      color: $color-gray-6;
      margin-bottom: 16px;
    }
    &__actions {
      display: flex;
      justify-content: space-between;
      line-height: 1.54;
      align-items: flex-end;
    }
    &__description {
      font-size: 1.3rem;
      color: $color-gray-6;
      margin-right: 66px;
    }
  }
  &__body {
    overflow-y: scroll;
    height: calc(100vh - 117px);
    padding-bottom: 40px;
  }
  &__item {
    padding: 20px;
    border-bottom: $box-border-dotted;
    position: relative;
    &__inputs {
      flex: 1;
    }
    &__image {
      background: $color-gray-fa;
      width: 120px;
      height: 120px;
      border: $box-border;
      background: url("./jpg-icon.svg") center no-repeat;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__close {
      cursor: pointer;
      position: absolute;
      top: 4px;
      right: 4px;
    }
    &__row {
      margin-bottom: 12px;
      display: flex;
      flex: 1 1 0px;
      justify-content: space-between;
      & > div {
        flex-basis: 100%;
        &:first-child {
          margin-right: 12px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__attachments {
    margin-top: 20px;
    margin-left: 24px;
    font-family: "Poppins";
    font-weight: 500;
    color: $color-gray-6;
    font-size: 13px;
  }
  &__add {
    margin-top: 20px;
    margin-left: 24px;
  }
  &__SwitchLabel {
    font-weight: 600;
    font-size: 8px;
    color: $color-gray-9;
    margin-bottom: 8px;
  }
  .MuiFormControl-root .MuiOutlinedInput-input {
    padding: 0 14px;
    height: 32px;
    line-height: 32px;
  }
  .MuiSelect-iconOutlined {
    right: 0;
    top: 9px;
  }
  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 11px) scale(1);
  }
}
