@import "../../../styles/consts.scss";

.EditLabeled {
  width: 522px;
  &__header {
    padding: 40px 32px 32px 40px;
    &__info {
      flex: 1;
    }
    &__personal {
      margin-bottom: 16px;
    }
    &__name {
      color: $color-gray-6;
      font-weight: 600;
      font-family: "Poppins";
      font-size: 1.8rem;
      margin-right: 12px;
    }
    &__nick {
      line-height: 1.17;
      font-size: 1.2rem;
      font-weight: 600;
      text-transform: uppercase;
      background: $color-gray-f2;
      padding: 0 5px;
      height: 16px;
    }
    &__misc {
      margin-bottom: 19px;
    }
    &__status {
      font-weight: 600;
      line-height: 1.45;
      font-size: 1.3rem;
      margin-right: 16px;
      span {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        display: inline-block;
        margin-right: 8px;
        &.orange {
          background: $color-primary;
        }
        &.gray {
          background: $color-gray-9;
        }
        &.green {
          background: $color-green;
        }
      }
    }
    &__driver {
      margin-left: 16px;
      font-weight: 600;
      color: $color-gray-9;
      line-height: 1.45;
      font-size: 1.3rem;
      margin-right: 16px;
    }
    &__driver-nick {
      color: $color-gray-9;
      line-height: 1.45;
      font-size: 1.3rem;
      margin-right: 16px;
    }
    .Button {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__tabs {
    width: 100%;
    &__item {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
      flex: 1;
      text-align: center;
      font-family: "Poppins";
      font-size: 1.3rem;
      font-weight: 600;
      color: $color-gray-9;
      border-bottom: 1px solid $color-gray-f2;
      &.active {
        color: $color-primary;
        border-bottom: 1px solid $color-primary;
      }
    }
  }
  &__summary {
    &__section {
      padding: 28px 24px;
      border-bottom: $box-border-dotted;
      &:last-child {
        border: none;
      }
    }
    &__title {
      margin-bottom: 20px;
      font-family: "Poppins";
      font-weight: 500;
      font-size: 1.3rem;
      color: $color-gray-6;
    }
    &__form {
      &__row {
        margin-bottom: 12px;
        display: flex;
        flex: 1 1 0px;
        justify-content: space-between;
        & > div {
          flex-basis: 100%;
          &:first-child {
            margin-right: 12px;
          }
        }
      }
    }
    &__driver-data {
      margin-bottom: 12px;
      span {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }
  .MuiFormControl-root .MuiOutlinedInput-input {
    padding: 0 14px;
    height: 32px;
    line-height: 32px;
  }
  .MuiSelect-iconOutlined {
    right: 0;
    top: 9px;
  }
  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 11px) scale(1);
  }
}
