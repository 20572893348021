@import "../../styles/consts.scss";

#BI {
  .react-grid-layout {
    position: relative;
    width: 100% !important;
    max-width: 1178px !important;
    transition: height 200ms ease;
    &.fullScreenActive {
      display: none;
    }
  }

  .react-grid-item {
    transition: all 500ms ease;
    transition-property: left, top;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    margin: 0 !important;
    border: 1px solid #ebebeb !important;
    border-radius: 3px !important;
    padding: 0 !important;

    .hideChart {
      display: none;
      position: absolute;
      top: 3px;
      right: 24px;
      width: 16px !important;
      height: 16px !important;
      z-index: 10000;
      cursor: pointer;
      img {
        width: 16px !important;
        height: 16px !important;
        padding: 0;
        cursor: pointer;
      }
    }

    .fullScreenActivator {
      display: none;
      position: absolute;
      top: 3px;
      right: 3px;
      width: 16px !important;
      height: 16px !important;
      z-index: 10000;
      cursor: pointer;
      img {
        width: 16px !important;
        height: 16px !important;
        padding: 0;
        cursor: pointer;
      }
    }

    .gridItemCovert {
      position: absolute;
      top: 3px;
      left: 3px;
      z-index: 10000;
      cursor: grabbing !important;
      img {
        display: none;
        width: 16px;
        height: 16px;
        padding: 0;
        cursor: grabbing !important;
      }
    }
  }

  .react-grid-item:hover {
    .hideChart,
    .fullScreenActivator {
      display: block;
    }
    .gridItemCovert {
      display: block;
      img {
        display: block;
      }
    }
  }
}

#BI {
  .react-grid-layout {
    .react-grid-item:hover {
      & > .react-resizable-handle {
        visibility: visible;
      }
    }
  }
}

#BI {
  .react-grid-layout.allGridItemProtected {
    > .react-grid-item {
      .gridItemCovert.totalCovert {
        //border: 1px solid blue!important;
        min-width: 100% !important;
        min-height: 100% !important;
      }
    }
  }
}

#BI {
  #ChartFullScreen {
    display: none;
    &.enabled {
      display: block;
      opacity: 1;
      position: absolute;
      top: 80px;
      left: 0;
      min-width: 100% !important;
      height: calc(100vh - 80px) !important;
      margin: 0;
      padding: 16px;
      background-color: white;
    }
    img {
      position: absolute;
      top: 16px !important;
      right: 16px !important;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

#BI {
  .react-grid-item img {
    pointer-events: none;
    user-select: none;
  }

  .react-grid-item.cssTransforms {
    transition-property: transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }

  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }

  .react-grid-item.dropping {
    visibility: hidden;
  }

  .react-grid-item.react-grid-placeholder {
    background: $color-gray-6;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    //background: $color-gray-6; //eb

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .react-grid-item > .react-resizable-handle {
    visibility: hidden;
    position: absolute;
    width: 20px;
    height: 20px;
  }

  .react-grid-item::after:hover {
    cursor: help !important;
  }

  .react-resizable-hide > .react-resizable-handle {
    display: none;
  }

  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 10px;
    height: 10px;
    border-right: 1px solid $color-gray-6;
    border-bottom: 1px solid $color-gray-6;
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    bottom: 3px;
    right: 3px;
    width: 16px;
    height: 16px;
    cursor: se-resize;
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-w,
  .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-n,
  .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
  }
}

#BI .react-grid-item > .react-resizable-handle::after {
  //border: none!important;
  background: $color-white !important;
  border-right: 1px solid $color-gray-6 !important;
  border-bottom: 1px solid $color-gray-6 !important;
}
