@import "../../../styles/consts.scss";

.Notifications {
  &__trigger {
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;
    .box-icon {
      position: relative;
    }
    .Notifications__tip {
      display: none;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: $zindex-tooltip;
      margin-top: 57px;
      border-radius: 3px;
      padding: 3px 8px 5px;
      box-shadow: 1px 1px 5px 0 #0000001e, 0 0 3px 0 #0000000f;
      background: $color-gray-3;
      font-weight: 600;
      font-size: 1.1rem;
      font-family: "Open Sans";
      white-space: nowrap;
      color: $color-white;
    }
    .Notifications__title--default {
      display: block;
    }
    .Notifications__title--active {
      display: none;
    }
    &--active,
    :hover {
      .Notifications__title--default {
        display: none;
      }
      .Notifications__title--active {
        display: block;
      }
    }
    &:hover {
      .Notifications__tip {
        display: block;
      }
    }
  }
  &__count {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: $color-primary;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: "Poppins";
    color: white;
  }
  &__panel {
    width: 415px;
    position: absolute;
    left: calc(100% + 16px);
    top: 16px;
    bottom: 64px;
    background: white;
    z-index: -1;
    border-radius: 3px;
    box-shadow: 1px 2px 9px 0 #00000011, 1px 1px 6px 0 #0000001e;
    overflow-y: scroll;
    font-family: "Open sans";
    &__title {
      font-family: "Poppins";
      font-size: 1.8rem;
      font-weight: 600;
      color: $color-gray-6;
      padding: 16px 24px;
    }
  }
  &__notification {
    border-top: $box-border;
    padding: 16px 24px;
    color: $color-gray-6;
    position: relative;
    cursor: pointer;
    &__icon {
      margin-right: 16px;
    }
    &__info {
      flex: 1;
    }
    &__title {
      font-size: 1.2rem;
      font-weight: 600;
      font-family: "Poppins";
      margin-bottom: 8px;
      line-height: 1.17;
    }
    &__body {
      font-size: 1.3rem;
      line-height: 1.54;
      font-weight: 600;
      margin-bottom: 5px;
    }
    &--read {
      .Notifications__notification {
        &__title {
          font-weight: 500;
        }
        &__body {
          font-weight: normal;
        }
      }
    }
    &__date {
      color: $color-gray-9;
      line-height: 1.45;
      font-weight: 600;
      font-size: 1.1rem;
      img {
        margin-left: 8px;
        padding-left: 8px;
        border-left: $box-border;
      }
    }
    &__delete {
      position: absolute;
      top: 4px;
      right: 4px;
    }
    &:hover {
      cursor: pointer;
      background-color: $color-gray-f5;
    }
    &:active {
      background-color: $color-orange-opacity6;
    }
  }
}
