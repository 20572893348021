@import "./consts.scss";

body {
  margin: 0;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 1.6rem;
  color: $color-gray-4d;
  background: $color-gray-fa;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px $color-white inset;
  box-shadow: 0 0 0px 1000px $color-white inset;
  transition: background-color 5000s ease-in-out 0s;
}

.MuiFormControl-root {
  width: 100%;
  .MuiOutlinedInput-input {
    height: 32px;
    padding: 0 14px;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: normal;
    line-height: 32px;
    color: $color-gray-4d;
  }
  .MuiFormLabel-root {
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: normal;
    line-height: 0.7;
    color: $color-gray-9;
  }
  .MuiFormLabel-root.Mui-focused {
    color: $color-primary;
  }
  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 11px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    font-family: "Open Sans" !important;
    padding-bottom: 10px;
    padding-right: 4px;
    transform: translate(14px, -4px) scale(0.75);
    background: $color-white !important;
  }
  .MuiInput-underline:after {
    border-bottom-color: $color-primary;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-radius: 3px;
      border: $box-border;
      background: transparent !important;
    }
    &:hover fieldset {
      border: $box-border-hover;
    }
    &.Mui-focused fieldset {
      border: $box-border-focus;
    }
  }
}

.MuiMenuItem-root {
  font-family: "Open Sans" !important;
  font-size: 11px !important;
  color: $color-gray-4d;
}

.cTable {
  .MuiTableCell-root {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
    white-space: nowrap;
  }
}

.bold {
  font-weight: 600;
}

.green {
  color: $color-green;
}

.red {
  color: $color-red;
}

.orange {
  color: $color-primary;
}

.gray {
  color: $color-gray-9;
}

// datepicket

.react-datepicker {
  border: none;
}

.react-datepicker__header {
  border: none;
  background: white;
}

.react-datepicker__month {
  margin-top: 12px;
}

.react-datepicker__current-month {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.5rem;
  color: $color-gray-6;
}

.react-datepicker__day {
  width: 32px;
  height: 32px;
  @include font-open-sans(600, 1.2rem, 32px);
  color: $color-gray-4d;
}

.react-datepicker__day-names {
  margin-top: 20px;
}

.react-datepicker__day-name {
  width: 32px;
  font-family: "Open Sans";
  font-size: 1.1rem;
  color: $color-gray-6;
}

.react-datepicker__current-month::first-letter {
  text-transform: uppercase;
}

.react-datepicker__day--disabled {
  color: $color-gray-c7 !important;
}

.react-datepicker__day--outside-month {
  color: $color-gray-c7 !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: $color-orange-item-selected !important;
  color: $color-gray-4d !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: $color-primary !important;
  color: $color-white !important;
}
