@import "../../../styles/consts.scss";

.Login {
  height: 100vh;
  width: 100vw;
  background: $color-gray-fa;
  &__wrapper {
    width: 536px;
    padding: 80px;
    border-radius: 3px;
    border: $box-border;
    background: white;
  }
  &__logo {
    margin-bottom: 40px;
  }
  &__title {
    font-size: 2rem;
    font-family: "Poppins";
    font-weight: 600;
    color: $color-gray-4d;
    margin-bottom: 32px;
  }
  &__text {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 32px;
    margin-top: -16px;
    color: $color-gray-4d;
  }
  .Input {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 7px;
    }
  }
  .Button {
    height: 48px;
    width: 100%;
    margin-top: 36px;
    font-size: 1.6rem;
  }
  &__remember {
    font-size: 1.3rem;
    .MuiTypography-body1 {
      font-size: 1.3rem;
    }
  }
  &__recovery {
    color: $color-primary;
    font-weight: 600;
    cursor: pointer;
  }
  &__error {
    margin-top: 12px;
    background: rgba(213, 63, 63, 0.05);
    padding: 8px;
    text-align: center;
    color: $color-red;
    font-size: 14px;
    line-height: 1.43;
    &__title {
      font-weight: 600;
    }
  }
}

.Login {
  .MuiFormControl-root {
    width: 100%;
    .MuiOutlinedInput-input {
      height: 48px !important;
      padding: 0 20px !important;
      font-family: "Open Sans" !important;
      font-size: 16px !important;
      line-height: 16px !important;
      color: $color-gray-4d !important;
    }
    .MuiFormLabel-root {
      font-family: "Open Sans" !important;
      font-size: 16px !important;
      line-height: 16px !important;
      color: $color-gray-9 !important;
    }
    .MuiFormLabel-root.Mui-focused {
      color: $color-primary !important;
    }
    .MuiInputLabel-outlined {
      z-index: 1 !important;
      transform: translate(20px, 15px) scale(1) !important;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      font-family: "Open Sans" !important;
      padding-bottom: 10px !important;
      padding-right: 4px !important;
      transform: translate(14px, -6px) scale(0.75) !important;
      background: $color-white !important;
    }
  }
}
