:root {
  --vh: 1vh;
  --vh20: calc(var(--vh, 1vh) * 20);
  --vh100: calc(var(--vh, 1vh) * 100);

  // Text editor settings

  --ck-color-base-focus: #ec754a !important;
  --ck-color-base-active: #fef4f1 !important; // dropdown active item
  --ck-color-base-active-focus: #fef4f1 !important; // dropdown active item focused (same color)
  --ck-color-list-button-hover-background: #f5f5f5 !important; // dropdown item hover
  --ck-color-widget-hover-border: #999 !important; // selected table border and handler
  --ck-widget-outline-thickness: 2px !important;
  --ck-color-focus-border-coordinates: 16, 81%, 61% !important; // used in table button animation

  .ck-editor__editable {
    min-height: 100px;
    p {
      margin: 6px 0 !important;
      line-height: 20px !important;
    }
  }

  .ck-editor__main > .ck-content {
    min-height: 99px !important; // editor content area min height 
    line-height: 10px !important;
  }

  .ck-dropdown__panel {
    overflow: hidden !important; // dropdown overflow and dimensions
    width: 155px !important;
  }

  .ck-button__label {
    color: #666 !important; // dropdown text color
  }

  .ck-button_with-text {
    &:hover {
      cursor: pointer !important; // dropdown cursor
    }
  }

  .ck-insert-table-dropdown-grid-box.ck-on {
    // table rows and columns selection
    border-color: #ec754a !important;
    background: #fef4f1 !important;
  }

  .ck-widget.table td.ck-editor__nested-editable.ck-editor__nested-editable_focused,
  .ck-widget.table td.ck-editor__nested-editable:focus {
    // table selected cells
    outline: 1px solid #ec754a !important;
    background: #fef4f1 !important;
  }

  .ck .ck-widget:not(.ck-widget_selected) > .ck-widget__type-around > .ck-widget__type-around__button {
    // insert paragraph icons
    background: #999 !important;
    &::before,
    &::after {
      background: #ec754a !important; // arrows hover
    }
  }

  .ck-widget__selection-handle > svg {
    // table drag icon
    path {
      fill: #ccc !important;
      fill-opacity: 1 !important;
      stroke: white !important;
      stroke-width: 3 !important;
      stroke-linecap: round !important;
    }
  }

  .ck-icon__selected-indicator {
    // table drag icon
    > path:last-child {
      fill: #ec754a !important;
      fill-opacity: 1 !important;
      stroke: white !important;
      stroke-width: 3 !important;
      stroke-linecap: round !important;
    }
  }

  // ck table selected
  .ck .ck-widget.ck-widget_selected,
  .ck .ck-widget.ck-widget_selected:hover {
    outline: 2px solid #ec754a !important; // table border
    > .ck-widget__selection-handle {
      background-color: #ec754a !important;
    }
    > .ck-widget__type-around > .ck-widget__type-around__button {
      background: #ec754a !important;
      &::after {
        background: #ec754a !important; // arrows hover
      }
    }
  }

  // ck list
  .ck-editor__main ul li,
  .ck-editor__main ol li {
    display: list-item;
    margin-bottom: 8px !important;
  }

  // End of text editor settings

  // Dropdown select settings

  .MuiListItem-root:hover {
    background: #f5f5f5 !important;
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background: #fef4f1 !important;
  }

  // End dropdown select settings

  .MuiSelect-iconOutlined {
    top: 9px !important;
    right: 10px !important;
    transform: scale(1.7) !important;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
}

a,
button {
  cursor: pointer;
}

a,
a:hover {
  color: inherit;
  text-decoration: inherit;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

button {
  user-select: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  box-shadow: none;
}

input[type="checkbox"] {
  cursor: pointer;
}

img {
  vertical-align: middle;
  border-style: none;
  -webkit-user-drag: none;
}

ul,
li {
  display: inline;
  margin: 0;
  padding: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

textarea {
  overflow: auto;
  resize: vertical;
}

/* Generic classes */

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.clearfix {
  width: 0;
  height: 0;
  clear: both;
  display: block;
  float: none;
  overflow: hidden;
  visibility: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
  border: 0;
  font-size: 0;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.flex {
  display: flex;
  flex-wrap: wrap;

  &.around {
    justify-content: space-around;
  }

  &.basis100 {
    flex-basis: 100%;
  }

  &.between {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  &.column {
    flex-direction: column;
  }

  .grow-2 {
    flex-grow: 2;
  }

  .grow-3 {
    flex-grow: 3;
  }

  .grow-4 {
    flex-grow: 4;
  }

  &.nowrap {
    flex-wrap: nowrap;
  }

  &.end {
    justify-content: flex-end;
  }

  &.evenly {
    justify-content: space-between;

    &:before,
    &:after {
      content: "";
      display: block;
    }
  }

  &.h-center {
    justify-content: center;
  }

  &.start {
    justify-content: flex-start;
  }

  &.v-center {
    align-items: center;
  }

  &.v-start {
    align-items: flex-start;
  }
}

.h100 {
  min-height: 100% !important;
}

.hidescroll {
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

.hidden,
[hidden] {
  display: none !important;
}

.visible,
[visible] {
  display: inherit !important;
}

.noscroll {
  overflow: hidden !important;
}

.overflow-hidden {
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

.uc-first:first-letter {
  text-transform: uppercase;
}

.visible {
  visibility: visible !important;
}

.w100 {
  min-width: 100% !important;
}
