@import "../../../styles/consts.scss";

#EditProfile {
  .PageHeader {
    position: relative;
    &__actions {
      display: block;
      position: absolute;
      top: 12px;
      right: 20px;
    }
    &__info {
      margin-right: 104px;
    }
  }
  .PageBody {
    margin-top: 16px;
  }
}

#EditProfile {
  .CardRowWrapper {
    margin-bottom: 0 !important;
    &:last-child {
      margin-bottom: 24px !important;
    }
  }
  .CardRowWrapper .Card {
    position: relative;
    .close-icon-rates {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
    .CardColumnIcon {
      margin-right: 16px;
      &__icon {
        top: -10px;
      }
    }
    .CardColumnForm .Row .Column {
      .dropdown {
        flex-grow: 0;
        position: relative;
        top: 3px;
        left: 0;
        margin-right: -2px;
        cursor: pointer;
      }
      .medium-counter {
        flex-grow: 1;
        position: relative;
        top: 8px;
        width: 90px !important;
        max-width: 90px !important;
        margin-right: 0 !important;
        @include font-poppins(500, 13px, normal);
        color: $color-gray-6;
      }
      .date {
        flex-grow: 1;
        width: 120px !important;
        max-width: 120px !important;
      }
    }

    .Row.rate-mediumtype {
      border-bottom: 1px solid $color-gray-eb;
      padding-bottom: 12px;
      &.first-row {
        border-top: 1px solid $color-gray-eb;
        padding-top: 12px;
      }
      &.last-row {
        margin-bottom: 0 !important;
        border-bottom: none;
      }
      .Column.half:last-child {
        min-width: 545px;
        width: 545px;
        .Input {
          min-width: 80px !important;
          width: 80px !important;
        }
      }
    }

    .close-mediumtype {
      position: relative;
      top: -8px;
      left: -5px !important;
      min-width: 12px !important;
      width: 12px !important;
      max-width: 12px !important;
      min-height: 12px !important;
      height: 12px !important;
      max-height: 12px !important;
      cursor: pointer;
    }

    .rates-mediumtypes-wrapper.hide {
      display: none;
    }
  }
}

#EditProfile {
  .CardRowWrapper {
    .Card {
      .CardColumnForm {
        .Row.row-labels {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-content: flex-start;
          flex-wrap: nowrap;
          width: 100% !important;
          min-width: 100% !important;
          margin: 0 !important;
          margin-top: 12px !important;
          padding: 0 !important;

          .label-selection-group {
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            flex-wrap: nowrap;
            width: 190px !important;
            max-width: 190px !important;
            margin: 0;
            padding: 0;

            .RadioGroupWrapper {
              position: relative;
              box-sizing: content-box;
              flex-grow: 1 !important;
              display: flex;
              justify-content: space-between;
              align-content: flex-start;
              width: 100% !important;
              margin: 0 !important;
              margin-right: 8px !important;
              padding: 0 !important;
              .RadioGroupLabel {
                position: absolute;
                top: 10px;
                left: 0;
              }
              .RadioGroup {
                height: 24px !important;
                margin-right: 0px;
                .RadioItemLabel:last-child {
                  margin-top: 0px !important;
                  padding-right: 0;
                }
              }
            }
          }

          .labels {
            box-sizing: content-box;
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: flex-start;
            flex-wrap: nowrap;
            .Chip {
              height: 24px;
              margin-right: 8px;
              margin-bottom: 0;
              border: 1px solid $color-gray-eb !important;
              border-radius: 3px;
              padding: 0 12px;
              background: white;
              transition: background-color 0.2s ease-in, border 0.2s ease-in, color 0.2s ease-in;
              @include font-open-sans(600, 11px, 1.45);
              color: $color-gray-6;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

body.menu-expanded {
  @media (max-width: 1279px) {
    #EditProfile {
      .CardRowWrapper:last-child .Button {
        max-width: 143px !important;
      }
      .CardColumnForm {
        margin-bottom: 38px !important;
        .Row.rate-mediumtype {
          position: relative !important;
          padding-right: 24px !important;
          padding-top: 12px !important;
          padding-bottom: 0 !important;
          .Column.half {
            position: relative;
            width: 100% !important;
            &:last-child {
              width: calc(100% + 12px) !important;
              .Input {
                width: 100% !important;
              }
            }
            .MuiSvgIcon-root.close-mediumtype {
              display: block;
              position: relative !important;
              top: -55px !important;
              right: -12px !important;
              left: unset;
              margin-right: -12px !important;
              cursor: pointer;
            }
          }
        }
        .Row.rate-mediumtype.header {
          align-items: flex-start !important;
        }

        .Column.rate-medium-type-column {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

body.menu-collapsed {
  @media (max-width: 1119px) {
    #EditProfile {
      .CardRowWrapper:last-child .Button {
        max-width: 143px !important;
      }
      .CardColumnForm {
        margin-bottom: 38px !important;
        .Row.rate-mediumtype {
          position: relative !important;
          padding-right: 24px !important;
          padding-top: 12px !important;
          padding-bottom: 0 !important;
          .Column.half {
            position: relative;
            width: 100% !important;
            &:last-child {
              width: calc(100% + 12px) !important;
              .Input {
                width: 100% !important;
              }
            }
            .MuiSvgIcon-root.close-mediumtype {
              display: block;
              position: relative !important;
              top: -55px !important;
              right: -12px !important;
              left: unset;
              margin-right: -12px !important;
              cursor: pointer;
            }
          }
        }
        .Row.rate-mediumtype.header {
          align-items: flex-start !important;
        }

        .Column.rate-medium-type-column {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

#EditProfile
  .CardRowWrapper
  .Card
  .CardColumnForm
  .Row.row-labels
  .label-selection-group
  .RadioGroupWrapper
  .RadioGroup {
  height: 36px !important;
  min-height: 36px !important;
}

#EditProfile {
  .row.accumulate {
    margin-bottom: 12px !important;
  }
  .AccumulateSurchargeLabel {
    position: relative;
    top: -6px;
    @include font-open-sans(600, 11px, normal);
    color: $color-gray-9;
  }
}