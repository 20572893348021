@import "../../../styles/consts.scss";

.ChangePassword {
  padding: 28px 36px;
  border-top: $box-border;
  &__title {
    margin-bottom: 20px;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 1.3rem;
    color: $color-gray-6;
  }
  &__form {
    padding: 4px 32px;
    &__tip {
      margin-bottom: 24px;
      font-size: 1.3rem;
      line-height: 1.54;
    }
    .Input {
      margin-bottom: 12px;
    }
    .Button:first-child {
      margin-right: 12px;
    }
  }
}
