@import "../../../styles/consts.scss";

.AddTicket {
  width: 522px;
  &__header {
    padding: 20px 24px 24px 24px;
    border-bottom: $box-border;
    &__title {
      font-family: "Poppins";
      font-size: 1.8rem;
      font-weight: 600;
      color: $color-gray-6;
      margin-bottom: 16px;
    }
    &__actions {
      display: flex;
      justify-content: space-between;
      line-height: 1.54;
      align-items: flex-end;
    }
    &__description {
      font-size: 1.3rem;
      color: $color-gray-6;
      margin-right: 66px;
    }
  }
  &__body {
    overflow-y: scroll;
    height: calc(100vh - 117px);
    padding-bottom: 40px;
  }
  &__item {
    padding: 20px;
    position: relative;
    &__close {
      cursor: pointer;
      position: absolute;
      top: 4px;
      right: 4px;
    }
    &__row {
      margin-bottom: 12px;
      display: flex;
      flex: 1 1 0px;
      justify-content: space-between;
      & > div {
        flex-basis: 100%;
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__add {
    margin-top: 20px;
    margin-left: 24px;
  }
  &__file-image {
    width: 16px;
    height: auto;
    margin-top: 4px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__files {
    &__item {
      &__wrapper-image {
        margin-right: 8px;
        width: 24px;
      }
      margin-right: 24px;
      color: $color-gray-6;
      font-size: 1.3rem;
      margin-top: 16px;
      img {
        max-width: 24px;
        max-height: 24px;
        width: 100%;
        height: auto;
      }
      &__delete {
        img {
          width: 16px;
          margin-left: 4px;
          cursor: pointer;
        }
      }
    }
  }
  &__attach {
    .Button {
      margin-top: 12px;
      height: 24px;
    }
    img {
      margin-right: 6px;
      width: 12px;
      height: 12px;
    }
  }
  .ck-editor__editable {
    min-height: 100px;
    p {
      margin: 6px 0 !important;
      line-height: 20px !important;
    }
  }
  .ck-editor__main {
    ul,
    ol {
      li {
        display: list-item;
        margin: initial;
        padding: initial;
      }
    }
  }
}
