@import "../../styles/consts.scss";

.Layout {
  .Layout__main-content--dashboard {
    padding: 0;
  }
}

.Dashboard {
  position: relative;
  &__stats {
    height: 270px;
    background: white;
    border-bottom: $box-border;
    position: sticky;
    top: 0;
    box-shadow: 4px 2px 10px 0 rgba(124, 124, 124, 0.04), 4px 4px 7px 0 rgba(0, 0, 0, 0.03);
    overflow: hidden;
    transition: ease height 0.3s;
    flex-wrap: nowrap !important;
    &.collapsed {
      height: 0;
    }
    &__filters {
      height: 76px;
      border-bottom: $box-border;
      .SelectBox {
        width: 194px;
        margin-right: 12px;
        &:last-child {
          margin: 0;
        }
      }
      &__wrapper {
        border: $box-border;
        padding: 8px 16px;
        border-radius: 3px;
        font-size: 1.1rem;
        &__text {
          color: $color-gray-9;
          padding-right: 12px;
        }
        &__item {
          padding-right: 12px;
          padding-left: 12px;
          border-left: $box-border;
          font-weight: 600;
          &:last-child {
            padding-right: 0;
          }
          .MuiTypography-root {
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 1.1rem;
            color: $color-gray-4d;
          }
        }
      }
    }
    &__charts {
      max-width: 1200px;
      align-self: center;
      &__drivers {
        padding: 19px 35px;
        width: auto;
        @media (max-width: 1365px) {
          padding: 19px 20px;
        }
        &--first {
          @media (max-width: 1155px) {
            display: none !important;
          }
        }
      }
      &__services {
        flex: 1;
        align-items: center;
        padding: 19px 40px 8px;
        border-right: $box-border;
        border-left: $box-border;
        @media (max-width: 1365px) {
          padding: 19px 20px 8px;
        }
        @media (max-width: 1155px) {
          border-left: none !important;
        }
      }
    }
  }
  &__chart {
    text-align: center;
    &__main {
      font-weight: 300;
      font-size: 4rem;
      font-family: "Poppins";
      line-height: 1.2;
      margin-bottom: 8px;
      @media (max-width: 1365px) {
        font-size: 3rem;
      }
      &--bar {
        @media (max-width: 1365px) {
          zoom: 0.8;
        }
      }
    }
    &__description {
      font-family: "Poppins";
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.33;
      color: $color-gray-9;
      .bold {
        color: $color-gray-6;
      }
    }
    &__time {
      font-size: 1.1rem;
      color: $color-gray-9;
    }
  }
  &__main {
    padding: 20px 40px;
    transition: ease height 0.3s;
    height: calc(100vh - 270px);
    &.expanded {
      height: 100vh;
    }
  }
  &__activity {
    flex: 1;
    margin-right: 16px;
    height: 100%;
    &__header {
      &__title {
        font-family: "Poppins";
        font-size: 2rem;
        font-weight: 600;
        color: $color-gray-6;
      }
      &__actions {
        margin-bottom: 12px;
        & > div:first-child {
          margin-right: 12px;
        }
        .SwitchBox {
          background: white;
          &__label {
            font-family: "Poppins";
          }
        }
      }
    }
    &__list {
      background: white;
      flex: 1;
      border-radius: 3px;
      border: $box-border;
    }
  }
  &__resources {
    position: relative;
    width: 256px;
    height: 100%;
    &__header {
      position: relative;
      &__title {
        font-family: "Poppins";
        font-size: 2rem;
        font-weight: 600;
        color: $color-gray-6;
        margin-bottom: 20px;
        margin-top: 16px;
      }
    }
    &__list {
      width: 256px !important;
      overflow-x: hidden;
      background: white;
      flex: 1;
      border-radius: 3px;
      border: $box-border;
    }
  }
  .os-scrollbar-vertical {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: -4px !important;
  }
}

.ResourceGroup {
  border-bottom: $box-border-dotted;
  &__title {
    height: 48px;
    font-family: "Poppins";
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;
    img {
      margin-right: 8px;
    }
    &__decorator {
      margin-left: 4px;
      height: 10px;
      width: 58px;
    }
    &--orange {
      color: $color-primary;
      .ResourceGroup__title__decorator {
        background-color: $color-primary;
      }
    }
    &--purple {
      color: $color-purple;
      .ResourceGroup__title__decorator {
        background-color: $color-purple;
      }
    }
    &--blue {
      color: $color-blue1;
      .ResourceGroup__title__decorator {
        background-color: $color-blue1;
      }
    }
    &--bluelight {
      color: $color-blue2;
      .ResourceGroup__title__decorator {
        background-color: $color-blue2;
      }
    }
    &--yellow {
      color: $color-yellow;
      .ResourceGroup__title__decorator {
        background-color: $color-yellow;
      }
    }
    &--green {
      color: $color-green;
      .ResourceGroup__title__decorator {
        background-color: $color-green;
      }
    }
    &--greenlight {
      color: $color-green2;
      .ResourceGroup__title__decorator {
        background-color: $color-green2;
      }
    }
    &--gray {
      color: $color-gray-b3;
      .ResourceGroup__title__decorator {
        background-color: $color-gray-b3;
      }
    }
  }
  &__item {
    padding: 10px 23px;
    cursor: pointer;
    &--orange {
      background-color: rgba(236, 117, 74, 0.1);
    }
    &--purple {
      background-color: rgba(221, 126, 208, 0.1);
    }
    &--blue {
      background-color: rgba(37, 152, 228, 0.1);
    }
    &--bluelight {
      background-color: rgba(6, 158, 173, 0.1);
    }
    &--yellow {
      background-color: rgba(220, 172, 33, 0.1);
    }
    &--greenlight {
      background-color: rgba(161, 178, 78, 0.1);
    }
    &--green {
      background-color: rgba(36, 164, 83, 0.1);
    }
    &--gray {
      background-color: rgba(179, 179, 179, 0.1);
    }
    &__photo {
      background: white;
      width: 28px;
      height: 28px;
      border-radius: 100%;
      border: 2px solid black;
      margin-right: 12px;
      border-radius: 100%;
      overflow: hidden;
      flex: 1;
      max-width: 28px;
      min-width: 28px;
      img {
        width: 100%;
        height: auto;
      }
      div {
        width: 100%;
        height: 100%;
        color: rgb(69, 162, 37);
        background: rgba(69, 162, 37, 0.1);
      }
      &--orange {
        border-color: $color-primary;
      }
      &--purple {
        border-color: $color-purple;
      }
      &--blue {
        border-color: $color-blue1;
      }
      &--bluelight {
        border-color: $color-blue2;
      }
      &--yellow {
        border-color: $color-yellow;
      }
      &--green {
        border-color: $color-green;
      }
      &--greenlight {
        border-color: $color-green2;
      }
      &--gray {
        border-color: $color-gray-b3;
      }
    }
    &__info-wrapper {
      width: calc(100% - 30px);
    }
    &__user {
      font-weight: 600;
      text-transform: capitalize;
      font-size: 1.1rem;
      &__name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex: 1;
      }

      &__tag {
        margin-left: 8px;
        background: $color-gray-f2;
        padding: 0 4px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.9rem;
      }
    }
    &__medium {
      color: $color-gray-9;
      font-size: 1.1rem;
      line-height: 1.09;
      &__plate {
        margin-left: 8px;
        text-transform: uppercase;
      }
      &__code {
        margin-left: 8px;
        background: $color-gray-fa;
        color: $color-gray-6;
        padding: 0 4px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.9rem;
      }
    }
  }
}

.LogMessage {
  padding: 16px 24px;
  border-bottom: $box-border-dotted;
  font-weight: 600;

  &__text {
    width: 60%;
    font-size: 13px;
    div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    span {
      color: $color-primary;
    }
    @media (max-width: 1200px) {
      width: calc(100% - 150px);
    }
  }
  &__company {
    width: 25%;
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  &__date {
    width: 15%;
    color: $color-gray-9;
    @media (max-width: 1200px) {
      width: 150px;
    }
  }
}

.Dashboard {
  div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
    min-width: 192px !important;
    margin-left: -12px !important;
    margin-top: 2px !important;
  }
}
