@import "../../styles/consts.scss";

#Payments .ListBody {
  .status {
    &__pending {
      color: $color-blue;
    }
    &__processed {
      color: $color-green3;
    }
  }
  .MuiTableCell-root.MuiTableCell-body {
    &:nth-child(7) {
      img {
        margin-right: 8px;
      }
    }
  }
}

.GeneratePaymentsForm,
.ProcessPaymentForm {
  .DateInput {
    margin: 0 16px 40px;
  }
}
