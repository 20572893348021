@import "../../../styles/consts.scss";

.AttachDocuments {
  padding: 24px 36px;
  &__name {
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 500;
    color: $color-gray-6;
    margin-bottom: 20px;
  }
  &__tip {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    color: $color-gray-6;
    margin-bottom: 24px;
  }

  &__list {
    &__item {
      width: 142px;
      margin-right: 12px;
      margin-bottom: 20px;
      position: relative;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &__placeholder {
        width: 142px;
        height: 80px;
        background: url("./document-placeholder.svg") center no-repeat;
        background-size: contain;
        cursor: pointer;
        position: relative;

        div {
          font-weight: 600;
          color: $color-gray-9;
          position: absolute;
          bottom: 8px;
          width: 100%;
          text-align: center;
          font-size: 10px;
        }
      }
      &__image {
        width: 142px;
        height: 80px;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
        }
      }
      &__pdf {
        border: $box-border;
        width: 142px;
        height: 80px;
      }
      &__remove {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
      }
      &__code {
        width: 100%;
        margin-top: 4px;
        font-weight: 600;
        color: $color-gray-6;
        line-height: 1.45;
        font-size: 1.1rem;
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
  .Button {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
}
