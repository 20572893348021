@import "../../../styles/consts.scss";

.SearchResources {
  background: white;
  width: 40px;
  position: absolute;
  right: 0;
  overflow: hidden;

  &.open {
    width: 100%;
  }
  .MuiFormControl-root .MuiOutlinedInput-input {
    font-size: 13px;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 12px;
  }

  .MuiOutlinedInput-adornedStart {
    cursor: pointer;
    padding-left: 12px;
  }
  .MuiFormControl-root .MuiOutlinedInput-root.Mui-focused fieldset {
    border: 1px solid $color-primary;
  }
}
