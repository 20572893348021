@import "../../../styles/consts.scss";

.AddDocProfile {
  width: 522px;
  &__header {
    padding: 20px 24px 24px 24px;
    border-bottom: $box-border;
    &__title {
      font-family: "Poppins";
      font-size: 1.8rem;
      font-weight: 600;
      color: $color-gray-6;
      margin-bottom: 16px;
    }
    &__actions {
      display: flex;
      justify-content: space-between;
      line-height: 1.54;
      align-items: flex-end;
    }
    &__description {
      font-size: 1.3rem;
      color: $color-gray-6;
      margin-right: 66px;
    }
  }
  &__body {
    overflow-y: scroll;
    height: calc(100vh - 117px);
    padding-bottom: 40px;
  }
  &__item {
    padding: 20px;
    border-bottom: $box-border-dotted;
    position: relative;
    &__close {
      cursor: pointer;
      position: absolute;
      top: 4px;
      right: 4px;
    }
    &__row {
      margin-bottom: 12px;
      display: flex;
      flex: 1 1 0px;
      justify-content: space-between;
      & > div {
        flex-basis: 100%;
        &:first-child {
          margin-right: 12px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__documentTypes {
    margin-top: 20px;
    margin-left: 24px;
    font-family: "Poppins";
    font-weight: 500;
    color: $color-gray-6;
    font-size: 13px;
  }
  &__add {
    margin-top: 20px;
    margin-left: 24px;
  }
  &__SwitchLabel {
    font-weight: 600;
    font-size: 8px;
    color: $color-gray-9;
    margin-bottom: 8px;
  }
}
