@import "../../styles/consts.scss";

.Toastify__toast {
  display: flex;
  align-items: center;
  right: 32px;
  width: 460px;
  border-radius: 2px;
  padding: 32px;
  box-shadow: 4px 2px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 7px 0 rgba(0, 0, 0, 0.11);
  background-color: $color-white;
  @include font-open-sans(normal, 1.6rem, 1.5);
  color: $color-gray-4d;
  &:before {
    content: "";
    z-index: 1;
    width: 38px;
    height: 38px;
    margin-right: 24px;
  }
  &-container {
    width: 460px;
  }
  &-body {
    width: 310px;
  }
  &__title {
    @include font-poppins(600, 1.6rem, 1.5);
  }
}

.Toastify__toast--default {
  border-left: 5px solid $color-white;
}

.Toastify__toast--success {
  &:before {
    background: url(./icons/toast-success.svg) center/auto 100% no-repeat;
  }
}

.Toastify__toast--info {
  &:before {
    background: url(./icons/toast-info.svg) center/auto 100% no-repeat;
  }
}

.Toastify__toast--error {
  &:before {
    background: url(./icons/toast-error.svg) center/auto 100% no-repeat;
  }
}

.Toastify__close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  color: $color-black !important;
}
